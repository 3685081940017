import * as utils from 'utils/src/utils';
import { denormalize, normalize, schema } from 'normalizr';
import actions from '../users/actionsTypes';
import { toast } from 'react-toastify';

// import { getUserTitle } from '../actions/Titles'

export const addUser = (payload: any) => ({
    type: actions.ADD_USER,
    payload
});

export const setStatus = (uid: string, statusText: string) => ({
    type: actions.SET_STATUS,
    payload: {
        uid,
        statusText
    }
});

export const changeUser = (uid: string, data: any) => ({
    type: actions.CHANGE_USER,
    payload: {
        uid,
        data
    }
})

export const setSubscribed = (uid: string, isSubscribedByMe: boolean) => ({
    type: actions.SET_SUBSCRIBE,
    payload: {
        isSubscribedByMe,
        uid
    }
})

export const setVirtUsers = (payload: any[]) => ({
    type: actions.SET_VIRT_USERS,
    payload
})

const forDelete = new Map<string, string[]>();
forDelete.set('baseData', ['analyticTagsList', 'analyticTags', 'country', 'region', 'subUser', 'value']);
forDelete.set('extData', ['myBadges', 'nfcIds', 'subUsers', 'subscribedUsers', 'thanksForAllByCollection']);
forDelete.set('profileData', [/*'userHeadFields', 'userInfoFields',*/ 'fbid', 'fsqid', 'linid', 'twid']);

export const updateUser = (uid: string, data: any) => {
    return (dispatch: any) => {
        // dispatch({ type: 'IS_LOADING_GROUP_FILES', payload: { gid } })
        const toSend = utils.cloneObject(data)

        const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
        // const categorys = new schema.Entity('categorys', { v: [field] }, { idAttribute: 'k' });

        const denormalizedInfo = denormalize(
            toSend.profileData.userInfoFields.result,
            [field],
            toSend.profileData.userInfoFields.entities
        );
        const denormalizedHead = denormalize(
            toSend.profileData.userHeadFields.result,
            [field],
            toSend.profileData.userHeadFields.entities
        );
        toSend.profileData.userInfoFields = denormalizedInfo;
        toSend.profileData.userHeadFields = denormalizedHead;

        for (const [key, value] of forDelete.entries()) {
            value.map(v => {
                delete toSend[key][v];
            });
        }
        dispatch(changeUser(data.baseData.id, data))
        utils.API.users.setData(toSend.baseData.id, toSend);
        utils.API.users.setImg(toSend.baseData.id, toSend.baseData.largeImgId).r.then((d:any) =>
            {
                console.log('Фото обновлено');
                if (d.recomendation)
                    toast.info(d.recomendation, { autoClose: false });
            });
    };
}

export const getUser = (uid: string) => {
    return (dispatch: any) => {
        // dispatch({ type: 'IS_LOADING_GROUP_FILES', payload: { gid } })
        utils.API.users.byId(uid, { fullData: true })
            .r
            .then((d: any) => {
                if (utils.checkResponseStatus(d)) {
                    const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
                    // const categorys = new schema.Entity('categorys', { v: [field] }, { idAttribute: 'k' });

                    const normalizedUserInfoFields = normalize(d.data.profileData.userInfoFields, [field]);
                    d.data.profileData.userInfoFields = normalizedUserInfoFields;
                    const normalizedUserHeadFields = normalize(d.data.profileData.userHeadFields, [field]);
                    d.data.profileData.userHeadFields = normalizedUserHeadFields;
                    dispatch(addUser({ user: d.data, uid: d.data.baseData.id }))
                    // dispatch(getUserTitle(d.data.baseData.displayName))
                } else {
                    // toast.error('something wrong')
                }
            });
    };
}

export const setUserStatus = (uid: string, statusText: string) => {
    return (dispatch: any) => {
        // dispatch({ type: 'IS_LOADING_GROUP_FILES', payload: { gid } })
        utils.API.users.setStatus(statusText)
            .r
            .then((d: any) => {
                if (d.error_code === 0 && d.error_text === 'OK') {
                    // 
                }
            });
        dispatch(setStatus(uid, statusText))
    };
}


export const subscribeByUser = (user: any) => {
    return (dispatch: any, getState: any) => {
        const isSubscribe = user.extData.isSubscribedByMe;
        utils.API.users.subscribe(user.baseData.id, !isSubscribe)
            .r
            .then((d: any) => {
                dispatch(setSubscribed(user.baseData.id, !isSubscribe))
            })
    }
}

export const loadVirtUsers = () => {
    return (dispatch: any, getState: any) => {
        utils.API.users.getUsersforimpersonation().r.then((d: any) => {
            if (utils.checkResponseStatus(d)) {
                dispatch(setVirtUsers(d.data))
            } else {
                // toast.error('something wrong')
            }
        })
    }
}