import './react-app-env.d';
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import './index.scss';
import './fonts.scss';
import * as serviceWorker from './serviceWorker';

import './utils-old/themes/default.css';
import './assets/fonts/pryaniky-icons/css/pryaniky-icons.css';
import './assets/fonts/editor-icons/css/prn-editor-icons.css';
import 'react-toastify/dist/ReactToastify.css';

import { DndProvider as DragDropContextProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import { Provider } from 'react-redux';
import { ConnectedRouter } from 'connected-react-router';
import { store } from './redux/store';
import { history } from './redux/browserHistory';
import 'utils/src/debug';
import 'utils/src/utils';
import 'utils/src/DialogCreator/style.scss';
import { Layout } from './blocks/Layout/Layout';
import { v1 as uuid } from 'uuid';
import { I18nReducerToContextProvider } from 'localizations/ReducerProvider';

store.subscribe(() => {
  // if (store.getState().i18n.namespace !== (window as any).currentNamespace) (window as any).currentNamespace = store.getState().i18n.namespace;
})

function init() {
  (window as any).auth = true;
}
init();

const l = document.createElement('link');
l.setAttribute('rel', 'icon');
l.setAttribute('type', 'image/png');
l.setAttribute('href', `/icon/32/favicon.png?v=${uuid()}`);
document.head.append(l);

ReactDOM.render(
  <React.StrictMode>
    <DragDropContextProvider backend={HTML5Backend}>
      <Provider store={store}>
        <I18nReducerToContextProvider>
          <ConnectedRouter history={history}>
            <Layout />
          </ConnectedRouter>
        </I18nReducerToContextProvider>
      </Provider>
    </DragDropContextProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
