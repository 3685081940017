import { ILoginActions } from "./actions.index";
import { ILoginReducer } from "./interfaces";
import { LOGIN_CHANGE_FIELD, LOGIN_AUTHENTICATE_ERROR, LOGIN_AUTHENTICATE_ERROR_CLEAR, LOGIN_SET_REQUEST, LOGIN_SET_SUBNETWORKS, LOGIN_AUTHENTICATE_SUCCESS, LOGIN_AUTHENTICATE_SUCCESS_CLEAR, LOGIN_SET_PROVIDERS, LOGIN_SELECT_PROVIDER, LOGIN_SET_SETTINGS, LOGIN_CHANGE_SERVICE, LOGIN_SET_CAPTCHA, LOGIN_CHANGE_REDUCER_FIELD } from "./actions";
import { _getCookie } from "utils/src/debug";

const st = '/sites/'; // sites in url

const chatIntegrationUrl = 'ChatIntegration';
const electronIntegration = 'client=electron';
const debugParam = 'client=localhost';

const companyNameValue = () => {
  // cn - company name
  let cn = '';
  let type: undefined | 'cookie' | 'sites' | 'url' = undefined;
  const stIdx = window.location.pathname.indexOf(st); // find sites in url
  if (stIdx >= 0) {
    cn = window.location.pathname.substring(stIdx + st.length, window.location.pathname.indexOf('/', stIdx + st.length));
    type = 'sites'
  } else
  // if (_getCookie('companyName')) {
  //   cn = _getCookie('companyName') || '';
  //   type = 'cookie'
  // } else
  if (window.location.hostname.split('.').length === 4 || window.location.hostname.search(/\d\.\d\.\d\.\d/) !== -1) {
    cn = window.location.hostname.split('.')[0];
    type = 'url'
  }
  return {
    cn,
    type
  }
}

const cn = companyNameValue();

const getIntegrations = (): ILoginReducer['integrations'] => {
  if(window.location.pathname.indexOf(chatIntegrationUrl) !== -1 || window.location.href.indexOf(electronIntegration) !== -1) return 'chat';
  if(window.location.search.slice(1).split(/[&]/).includes(debugParam)) return 'debug';
  return undefined;
};

const baseState: ILoginReducer = {
  companyNameIsExist: cn.type,
  integrations: getIntegrations(),
  request: false,
  subnetworks: [],
  service: {
    codeSended: false,
    codeAlreadySended: false
  },
  values: {
    companyName: cn.cn,
    user: '',
    password: '',
    rememberMe: false,
    newPassword: '',
    newPasswordConfirm: '',
    captchaId: '',
    captchaValue: ''
  },
  captcha: null,
  providers: [],
  errors: {},
  success: {},
  settingsLoading: false
}

export const loginReducer = (state = baseState, action: ILoginActions): ILoginReducer => {
  switch(action.type) {
    case LOGIN_SET_SETTINGS: {
      return {
        ...state,
        ...action.payload
      }
    }
    case LOGIN_AUTHENTICATE_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload
        }
      }
    }
    case LOGIN_AUTHENTICATE_SUCCESS: {
      return {
        ...state,
        success: {
          ...state.success,
          ...action.payload
        }
      }
    }
    case LOGIN_AUTHENTICATE_ERROR_CLEAR: {
      return {
        ...state,
        errors: {}
      }
    }
    case LOGIN_AUTHENTICATE_SUCCESS_CLEAR: {
      return {
        ...state,
        success: {}
      }
    }
    case LOGIN_SET_REQUEST: {
      return {
        ...state,
        request: action.payload !== undefined ? action.payload : !state.request
      }  
    }
    case LOGIN_CHANGE_FIELD: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      }
    }
    case LOGIN_SET_SUBNETWORKS: {
      return {
        ...state,
        subnetworks: action.payload
      }
    }
    case LOGIN_SET_PROVIDERS: {
      // const activeProviderChange = state.providers.reduce((active, provider) => provider.selected ? provider.id : active , '') !== action.payload.reduce((active, provider) => provider.selected ? provider.id : active , '')
      return {
        ...state,
        values: {
          ...state.values,
          // ...(activeProviderChange && { user: '', password: '' })
        },
        providers: action.payload
      }
    }
    case LOGIN_SELECT_PROVIDER: {
      return {
        ...state,
        values: {
          ...state.values,
          user: '',
          password: ''
        },
        providers: state.providers.map(provider => ({ ...provider, selected: provider.id === action.payload }))
      }
    }
    case LOGIN_CHANGE_SERVICE: {
      return {
        ...state,
        service: {
          ...state.service,
          ...action.payload
        }
      }
    }
    case LOGIN_SET_CAPTCHA: {
      return {
        ...state,
        captcha: action.payload
      }
    }
    case LOGIN_CHANGE_REDUCER_FIELD: {
      return {
        ...state,
        [action.payload.field]: action.payload.value
      };
    }
    default:
      return state;
  }
}