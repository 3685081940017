import { connect } from 'react-redux';
import { compose } from '@bem-react/core';
import * as React from 'react';
// import { i18n } from "localization";

import { cnBackground, IBackgroundProps, IBackgroundState, mapStateToProps, mapBackgroundActionsToProps } from './Background.index';

import './Background.scss';
import background from './background.png';
import * as utils from 'utils/src/utils';

class BackgroundPresenter extends React.Component<IBackgroundProps, IBackgroundState> {
  public el: HTMLElement | null;
  public state = {
    // back: background,
    back: '',
    backColor: 'EBEFF2',
  };

  public componentDidMount() {
    this.setBackColor();
    // this.setBackImage();
  }
  public componentWillUnmount() {
    document.body.style.backgroundColor = 'transparent';
  }
  public componentWillReceiveProps(np: any) {
    // this.setBackColor();
  }

  public render() {
    const { className = '', auth, children, backgroundUrl } = this.props;
    return (
      <div
        ref={el => this.el = el}
        style={{ backgroundImage: `url(${backgroundUrl ? backgroundUrl : this.state.back})` }}
        className={cnBackground({ noAuth: !auth }, [className])}>
        {children}
      </div>
    )
  }

  private setBackColor = () => {
    if (!this.props.auth) {
      document.body.style.backgroundColor = '#EBEFF2';
    } else {
      let backColor = Number(this.state.backColor).toString(16);
      if (backColor.length === 3) {
        // duplicate each char
        backColor = backColor.split('').map(char => char + char).join('')
      } else if (backColor.length < 6) {
        // add trainling zeros
        backColor = Array.from({ length: 6 - backColor.length }, _ => '0').join('') + backColor;
      }
      document.body.style.backgroundColor = '#' + backColor;
    }
  }

  // public setBackImage = () => {
  //   const dev_cn = utils.getCookie('DEV_companyName');
  //   const cn = utils.getCookie('companyName');
  //   if (!dev_cn && !cn) {
  //     if (!utils.isTestServer() && utils.isAuthInQueryString()) {
  //       const data = window.location.search.split('&');
  //       const [companyName] = data[0].split('=')[1].split(';');
  //       if (companyName !== '') {
  //         utils.setCookie({ name: 'DEV_companyName', value: companyName });
  //         // document.cookie = `DEV_companyName=${companyName};path=/; samesite=None; secure`;
  //         try {
  //           fetch(utils.API.background.getOld().replace('.app', ''))
  //             .then(r => r.blob())
  //             .then(d => {
  //               const { size } = d;
  //               if (size > 500) {
  //                 this.props.changeVm('appDesign.backgroundUrl', URL.createObjectURL(d));
  //               }
  //             });
  //         } catch (error) {
  //           console.error('set background error:', error);
  //         }
  //       }
  //     }
  //   } else {
  //     try {
  //       fetch(utils.API.background.getOld().replace('.app', ''))
  //         .then(r => r.blob())
  //         .then(d => {
  //           const { size } = d;
  //           if (size > 500) {
  //             this.props.changeVm('appDesign.backgroundUrl', URL.createObjectURL(d));
  //           }
  //         });
  //     } catch (error) {
  //       console.error('set background error:', error);
  //     }
  //   }
  // }

}

export const Background = connect(
  mapStateToProps,
  mapBackgroundActionsToProps
)(BackgroundPresenter)