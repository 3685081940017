import { cn } from '@bem-react/classname';
import { RouteComponentProps } from 'react-router';
import { setAuthUser, getAuthUser } from 'redux/base/actions';
import { changeUser } from 'redux/users/actions';
import { rootTypeAction } from 'redux/actions'
// import { updateContext, setContext } from 'redux/actions/Widgets';
import { loadCompanyPostTypes } from 'redux/actions/AppSettings'
import { IModuledStoreType } from 'redux/store';
import { bindActionCreators, Action, Dispatch } from "redux";
import actions from '../../redux/base/actionsTypes';
import { i18n_check_language_namespace } from 'utils/src/CommonRedux/i18n/actions';

export interface ILayoutProps extends ILayoutOwnProps, RouteComponentProps, IMapLayoutStateToProps, ILayoutActionsToProps {}

export interface ILayoutOwnProps {}

export interface ILayoutState {}

export const cnLayout = cn('Layout');

// export const LayoutActionsToProps = {
//   rootTypeAction,
//   setAuthUser,
//   changeUser,
//   // updateContext,
//   // setContext,
//   loadCompanyPostTypes
// }

export const mapLayoutActionsToProps = (dispatch: Dispatch<Action>, ownProps: ILayoutOwnProps) => bindActionCreators({
  rootTypeAction,
  setAuthUser,
  changeUser,
  // updateContext,
  // setContext,
  loadCompanyPostTypes,
  i18n_check_language_namespace,
  changeVm: (propPath: string, value: any) => dispatch({
    type: actions.CHANGE_STATE, payload: { propPath, value } 
  })
}, dispatch);

// type ILayoutActionsToProps = ReturnType<typeof mapLayoutActionsToProps>;

export type ILayoutActionsToProps = ReturnType<typeof mapLayoutActionsToProps>;

export const mapLayoutStateToProps = (state: IModuledStoreType) => ({
  authUserNew: getAuthUser(state),
  auth: state.store.auth,
  edit: state.store.edit,
  loading: state.store.loading,
  homePage: state.store.appSettings?.homePage,
  analytics: state.store.appSettings && state.store.appSettings.analytics || {},
});

export type IMapLayoutStateToProps = ReturnType<typeof mapLayoutStateToProps>;