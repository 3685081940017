import { IRegistrationActions } from "./actions.index";
import { IRegistrationReducer } from "./interfaces";
import { REGISTRATION_CHANGE_FIELD, REGISTRATION_CHANGE_ERRORS, REGISTRATION_SET_AUTOREGISTRATION, REGISTRATION_CHANGE_REDUCER_FIELD } from "./actions";
import { IRegistrationFields, IrequiredFields } from './interfaces'
import { IAutoregistration, IRegistrationData } from "utils/src/requests/models/api.system";
import { registrationsValidateFileldsRules } from './saga';

export const registrationRequiredFields: IrequiredFields = {
  firstName: true,
  lastName: true,
  email: 'phone',
  phone: 'email',
  code: (data: IRegistrationFields, settings: IRegistrationReducer['autoregistration']) => settings.useCode,
  ...(window as any).AdditionalRegistrationRequiredFields
};

const getRegistrationRequired = (data: IRegistrationFields, settings: IRegistrationReducer['autoregistration']) => 
  Object.keys(registrationRequiredFields).reduce((acc, k) => {
    let rez = false;
    const key = k as keyof IRegistrationFields;
    const value = registrationRequiredFields[key];
    switch (typeof value) {
      case 'boolean':
        rez = !!value;
        break;
      case 'string':
        rez = !data[value as keyof IRegistrationFields];
        break;
      case 'function': 
        rez = value(data, settings);
        break;
    }
    if(rez) acc.push(key);
    return acc;
  }, [] as (keyof IRegistrationFields)[])

const baseFields = {
  comment: '',
  division: '',
  email: '',
  firstName: '',
  imgId: '',
  lastName: '',
  middleName: '',
  phone: '',
  position: '',
  code: '',
  sitename: ''
}

const baseAutoregistration: IRegistrationReducer['autoregistration'] = {
  allowedDomains: [],
  enable: false,
  useCode: false,
  enableAgreement: false,
  enableWithTrustedDomains: false,
  fields: []
}

const baseState: IRegistrationReducer = {
  fields: baseFields,
  reqiredFields: getRegistrationRequired(baseFields, baseAutoregistration),
  autoregistration: baseAutoregistration,
  sended: false
}

export const registrationReducer = (state = baseState, action: IRegistrationActions): IRegistrationReducer => {
  switch(action.type) {
    case REGISTRATION_CHANGE_FIELD: {
      const fields = {
        ...state.fields,
        [action.payload.field]: action.payload.value
      }
      return {
        ...state,
        fields,
        reqiredFields: getRegistrationRequired(fields, state.autoregistration),
        autoregistration: {
          ...state.autoregistration,
          fields: state.autoregistration.fields.map(el => {
            if(el.fieldId === action.payload.field) {
              return { ...el, [action.payload.valueType || 'value']: action.payload.value };
            }
            return el;
          })
        }
      }
    }
    case REGISTRATION_CHANGE_ERRORS: {
      let errors = {
        ...state.errors,
        ...action.payload
      }
      Object.keys(errors).forEach((key) => {
        if(!errors[key as keyof typeof errors] || !state.reqiredFields.includes(key as keyof typeof errors)) delete errors[key as keyof typeof errors];
      });
      return {
        ...state,
        errors
      }
    }
    case REGISTRATION_SET_AUTOREGISTRATION: {
      let { allowedDomains, ...payload } = action.payload;
      if(!allowedDomains) allowedDomains = [];
      const autoregistration = {
        allowedDomains: allowedDomains.filter(e => e),
        useCode: payload.useCode,
        ...payload.autoRegistration
      }
      // payload.autoRegistration.fields.
      // const mandatoryFields = payload.autoRegistration.fields.filter(e => e.isMandatory).reduce((a, c) => ({ ...a, [c.id]: c.isMandatory }), {} as typeof state.fields)
      const mandatoryFields = payload.autoRegistration.fields.filter(e => e.field.isMandatory);
      mandatoryFields.forEach(el => {
        registrationRequiredFields[el.fieldId] = true;
        registrationsValidateFileldsRules[el.fieldId] = {
          ...registrationsValidateFileldsRules[el.fieldId],
          notNull: {
            value: true
          }
        }
      });
      return {
        ...state,
        autoregistration,
        reqiredFields: getRegistrationRequired(state.fields, autoregistration)
      }
    }
    case REGISTRATION_CHANGE_REDUCER_FIELD: {
      return {
        ...state,
        [action.payload.field]: action.payload.value
      }
    }
    default:
      return state;
  }
}