import { ISagaModule } from 'redux-dynamic-modules-saga';
import { IRegistrationReducer } from './interfaces';
import { registrationReducer } from './reducer';
import { registrationSaga } from './saga';

type IRegistrationModule = { registration: IRegistrationReducer } 

export function getRegistrationModule(): ISagaModule<IRegistrationModule>  {
  return {
    id: "registration",
    reducerMap: {
      registration: registrationReducer,
    },
    sagas: [
      registrationSaga
    ],
    initialActions: [
    ]
  };
}