// import { IClassNameProps } from '@bem-react/core';
import { bindActionCreators, Action, Dispatch } from "redux";
import { cn } from '@bem-react/classname';
import { ActionCreator, AnyAction } from 'redux';
import { RouteComponentProps } from 'react-router';
import { IClassNameProps } from '@bem-react/core';
import { IModuledStoreType } from 'redux/store';
// import { setBackgroundImg } from '../../redux/base/actions';
// import { getLoginValues, getLoginErrors, getLogin, getLoginSuccess, getLoginProviders, getSelectedLoginProvider } from '../../redux/login/selectors';
// import { login, login_error, login_change_field, login_error_clear, login_get_company_settings, login_success, login_success_clear, login_select_provider, login_send_sms_sode } from '../../redux/login/actions';
import { getRegistrationAutoregistration } from "redux/registration/selectors";
import { login_get_company_settings } from 'redux/login/actions';

export const cnLogin = cn('Login');

export interface ILoginProps extends ILoginOwnProps, IClassNameProps, RouteComponentProps, IMapLoginStateToProps, IMapLoginActionsToProps {};

export interface ILoginOwnProps {
  setAPIDomain: ActionCreator<AnyAction>;
  action?: () => {};
  layout: React.Component<any, any>;
  setBaseData: (callback?: () => void) => void;
};

export interface ILoginState {
  text?: string;
  showCode?: boolean;
};

export interface ILoginData {
  companyName: string;
  login: string;
  password: string;
  rememberMe?: boolean;
};

export const mapLoginStateToProps = (state: IModuledStoreType, props: ILoginOwnProps) => ({
  // values: getLoginValues(state),
  // errors: getLoginErrors(state),
  // success: getLoginSuccess(state),
  // companyNameIsExist: getLogin(state).companyNameIsExist,
  // integrations: getLogin(state).integrations,
  // request: getLogin(state).request,
  // subnetworks: getLogin(state).subnetworks,
  // providers: getLoginProviders(state),
  // selectedProvider: getSelectedLoginProvider(state),
  // autoregistration: getRegistrationAutoregistration(state as any)
  autoregistration: getRegistrationAutoregistration(state),
});

export type IMapLoginStateToProps = ReturnType<typeof mapLoginStateToProps>;

export const mapLoginActionsToProps = (dispatch: Dispatch<Action>, ownProps: ILoginOwnProps) => bindActionCreators({
  // login,
  // login_error,
  // login_change_field,
  // setBackgroundImg,
  // login_error_clear,
  login_get_company_settings,
  // login_success,
  // login_success_clear,
  // login_select_provider,
  // login_send_sms_sode
}, dispatch);

export type IMapLoginActionsToProps = ReturnType<typeof mapLoginActionsToProps>;