import * as utils from 'utils/src/utils';
// import * as utilsProj from "utils.project/utils.project";
import { IModuledStoreType } from "./store";
import { constants } from 'utils/src/constants.prn';
import actions from './base/actionsTypes';

export const PRN_ACTION = (action: string) => `${constants.PREFIX}_${action}`
export const SET_STRUCTURE = 'SET_STRUCTURE';

export const rootTypeAction = (): any => { }

// export const changeVm = (propPath: string, value: any): any => ({
//   type: actions.CHANGE_STATE,
//   payload: { propPath, value }
// })

export const saveDesign = () => {
  return (dispatch: any, getState: () => IModuledStoreType) => {
    // const qew = getState().lists
    // const asd = getState().modals
    const { appDesign } = getState().store;
    utils.API.system.setDesign({ customCss: appDesign.customCss, customJs: appDesign.customJs, title: appDesign.title })
    .r
    .then((d: any) => {
      if (utils.checkResponseStatus(d)) {
        // utilsProj.setCustomDesign(appDesign)
      }
    })
  }
};