import { IRoute } from './routes.index';
import { urls } from './urls';
import { Login } from '../blocks/Login/Login';
import { Feedback } from '../blocks/Feedback/Feedback';
import { Forgot } from '../blocks/Forgot/Forgot';
import { Reset } from '../blocks/Reset/Reset';
import { Logout } from '../blocks/Logout/Logout';
import { Registration } from '../blocks/Registration/Registration';

export const exactPathnames: string[] = [];

export const routes: IRoute[] = [
  {
    path: [urls.login, urls.registration].reverse(),
    c: {
      c: Login
    }
  },
  // make double for not crash ioher logic
  // {
  //   path: urls.registration,
  //   c: {
  //     c: Login
  //   }
  // },
  {
    path: urls.feedback,
    c: {
      c: Feedback
    }
  },
  {
    path: urls.forgot,
    c: {
      c: Forgot
    }
  },
  {
    path: urls.resetPassowrd,
    c: {
      c: Reset
    }
  },
  {
    path: urls.logout,
    c: {
      c: Logout
    }
  }
].map((el: IRoute) => {
  if (!el.prp) el.prp = {};
  if (typeof el.path === 'string') {
    el.prp.exact = exactPathnames.includes(el.path);
  }
  return el;
})

export default {
  routes
}