import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { IModuledStoreType } from '../../../../redux/store';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { getRegistrationAutoregistration, getRegistrationFileds, getRegistrationFiledsErrors, getRegistrationReuiredFields, getRegistrationFieldByName} from '../../../../redux/registration/selectors';
import { registrationChangeField, registrationSubmit, registrationChangeReducerField } from '../../../../redux/registration/actions';

export const cnRegisterForm = cn('RegisterForm');

export interface IRegisterFormProps extends IRegisterFormOwnProps, IClassNameProps, ImapRegisterFormStateToProps, ImapRegisterFormActionsToProps {

};

export interface IRegisterFormOwnProps {

};

export const mapRegisterFormStateToProps = (state: IModuledStoreType, props: IRegisterFormOwnProps) => ({
  fields: getRegistrationFileds(state),
  errors: getRegistrationFiledsErrors(state),
  reqired: getRegistrationReuiredFields(state),
  settings: getRegistrationAutoregistration(state),
  sended: getRegistrationFieldByName(state, 'sended')
});

export type ImapRegisterFormStateToProps = ReturnType<typeof mapRegisterFormStateToProps>;

export const mapRegisterFormActionToProps = (d: Dispatch<Action>) => bindActionCreators({
  registrationChangeField,
  registrationSubmit,
  registrationChangeReducerField
}, d);

export type ImapRegisterFormActionsToProps = ReturnType<typeof mapRegisterFormActionToProps>;