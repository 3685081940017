import { IFeedbackSettings } from "utils/src/requests/models/api.system";
import { IFeedbackActions, IFeedbackActionPayload } from "./actions.index";
import { IFeedbackAdditionalData } from "./interfaces";

export const FEEDBACK = 'FEEDBACK';
export const FEEDBACK_ERROR = 'FEEDBACK_ERROR';
export const FEEDBACK_ERROR_CLEAR = 'FEEDBACK_ERROR_CLEAR';
export const FEEDBACK_CHANGE_FIELD = 'FEEDBACK_CHANGE_FIELD';
export const FEEDBACK_SET_REQUEST = 'FEEDBACK_SET_REQUEST';
export const FEEDBACK_VALUES_CLEAR = 'FEEDBACK_VALUES_CLEAR';
export const FEEDBACK_GET_ADDITIONAL_DATA = 'FEEDBACK_GET_ADDITIONAL_DATA';
export const FEEDBACK_SET_ADDITIONAL_DATA = 'FEEDBACK_SET_ADDITIONAL_DATA';
export const FEEDBACK_GET_SETTINGS = 'FEEDBACK_GET_SETTINGS';
export const FEEDBACK_PUT_SETTINGS = 'FEEDBACK_PUT_SETTINGS';

export function feedback(payload: IFeedbackActionPayload): IFeedbackActions {
  return {
    type: FEEDBACK,
    payload
  }
}

export function feedback_error(payload: {[s: string]: string}): IFeedbackActions {
  return {
    type: FEEDBACK_ERROR,
    payload
  }
}

export function feedback_error_clear(): IFeedbackActions {
  return {
    type: FEEDBACK_ERROR_CLEAR
  }
}

export function feedback_change_field(payload: {[s: string]: string | boolean}): IFeedbackActions {
  return {
    type: FEEDBACK_CHANGE_FIELD,
    payload
  }
}

export function feedback_set_request(payload?: boolean): IFeedbackActions {
  return {
    type: FEEDBACK_SET_REQUEST,
    payload
  }
}

export function feedback_values_clear(): IFeedbackActions {
  return {
    type: FEEDBACK_VALUES_CLEAR
  }
}

export function feedback_get_settings(): IFeedbackActions {
  return {
    type: FEEDBACK_GET_SETTINGS
  } 
}

export function feedback_put_settings(payload: IFeedbackSettings): IFeedbackActions {
  return {
    type: FEEDBACK_PUT_SETTINGS,
    payload
  } 
}