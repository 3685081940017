import actions from './actionsTypes';
import { store, IModuledStoreType } from '../store';
import { GUID_EMPTY } from 'utils/src/constants.prn';

export const toggle = (variable: string, value?: boolean) => ({
  type: actions.TOGGLE,
  payload: {
    variable,
    value
  }
})

export const toggleAuth = (value?: boolean) => ({
  type: actions.TOGGLE,
  payload: {
    variable: 'auth',
    value
  }
})

export const toggleLoading = (value?: boolean) => ({
  type: actions.TOGGLE,
  payload: {
    variable: 'loading',
    value
  }
})

export const setAuthUser = (payload: string) => ({
  type: actions.SET_AUTH_USER,
  payload
})

export const clearAuthUser = () => ({
  type: actions.CLEAR_AUTH_USER
})

export const windowFocus = () => ({
  type: actions.WINDOW_FOCUS
})

export const windowBlur = () => ({
  type: actions.WINDOW_BLUR
})

export const setBackgroundImg = (url: string) => ({
  type: actions.SET_BACKGROUND,
  payload: url
})

export const getAuthUser = (state?: IModuledStoreType) => {
  if (state) return state.users.users[state.store.authUid];
  const cs = store.getState();
  // return cs.users.users[cs.store.authUid];
}
