import { put, takeEvery, takeLeading, delay, takeLatest } from 'redux-saga/effects';
import { call, select } from 'utils/src/saga.effects';
import { push } from 'connected-react-router';
import { ILoginAction, ILoginPasswordReset, ILoginLogoutAction, ILoginPasswordSet, ILoginFirstlogin, ILoginGetCompanySettingsAction, ILoginChangeField, ILoginSendSmsCodeAction, ILoginCheckUserInvitationAction, ILoginCaptchaUpdate } from './actions.index';
import { getLoginValues, getLogin, getLoginSubnetworks, getLoginIsExistCompanyName, getSelectedLoginProvider, getLoginIntegrations } from './selectors';
import { LOGIN_AUTHENTICATE, login_select_provider, login_error, login_error_clear, login_set_request, LOGIN_PASSWORD_RESET, LOGIN_LOGOUT, login, LOGIN_FIRST_LOGIN, LOGIN_PASSWORD_SET, login_change_field, LOGIN_GET_COMPANY_SETTINGS, login_set_subnetworks, login_success, login_set_providers, LOGIN_CHANGE_FIELD, LOGIN_SEND_SMS_CODE, LOGIN_CHECK_USER_INVITATION, login_set_settigns, login_change_service, login_set_captcha, LOGIN_UPDATE_CAPTCHA, login_change_reducer_field } from './actions';
import { getRouter } from 'connected-react-router';
import { ILoginValues, ILoginReducer, ICompanyNameIsExist, ILoginProvider } from './interfaces';
import { API, checkResponseStatus, getCookie, setCookie, getErrorText } from 'utils/src/utils';
import { i18n } from "localization";
import { toast } from 'react-toastify';
import { IModuledStoreType } from 'redux/store';
import { urls } from 'routes/urls';
import { basename, deleteAllCookies } from 'utils/src/utils';
import { IResponseWithData } from 'utils/src/requests/models/api.base';
import { ILoginSendSmsCodeResponseData, ILoginSettingsResponseData, ISubnetwork } from 'utils/src/requests/models/api.system';
import BaseRequests, { basenameRegex } from 'utils/src/requests/requests.base';
import { baseProvidersRedirect, loginProvidersOnWeb, useLoginProvidersOnWeb, providersWithLoginPassFrom } from '../../constants';
import { registrationSetAutoregistration } from 'redux/registration/actions';
import { i18n_set_avaliable_languages, i18n_set_language } from 'utils/src/CommonRedux/i18n/actions';
import baseActions from 'utils/src/CommonRedux/base/actionsTypes';
import { leastIndex } from 'd3-array';
import { registrationRequiredFields } from '../registration/reducer';
import { setCustomDesign } from 'blocks/Layout/customCss';
import AppSettingsActions from '../actionTypes/AppSettings';

export const getExpires = (data: ILoginValues) => {
  if (!data.rememberMe) return '';
  const date = new Date();
  date.setFullYear(date.getFullYear() + 2);
  return date.toUTCString();
}

/**
 * проверяет является выбранная сеть родительской или нет, возвращает boolean(true/false)
 * @param { ISubnetwork[] } subnetworks 
 * @param { string } value 
 */
export const isSelectedNetworkIsParent = (subnetworks: ISubnetwork[], value: string) => subnetworks.filter(e => e.isParentNetwork).map(e => e.name).includes(value)

/**
 * проверяет на неродительскую сеть, возвращает паттерн /sites/{value}
 * @param { ISubnetwork[] } subnetworks 
 * @param { string } value 
 */
export const getNewSites = (subnetworks: ISubnetwork[], value: string) => subnetworks.length > 1 && !isSelectedNetworkIsParent(subnetworks, value) ? `/sites/${value}` : ``

function* loginRequest(action: ILoginAction) {
  try {
    deleteAllCookies(['reload-redirect', 'DEV_companyName']);
    yield put(login_error_clear());
    yield put(login_set_request(true));
    const integrations: ILoginReducer['integrations'] = yield* select(getLoginIntegrations);
    const data: ILoginValues = yield* select(getLoginValues);
    const subnetworks: ISubnetwork[] = yield* select(getLoginSubnetworks);
    BaseRequests.apiBasename = BaseRequests.apiBasename.replace(basenameRegex, getNewSites(subnetworks, data.companyName));
    const expires = getExpires(data);
    if (
      window.location.host.indexOf('localhost') !== -1 ||
      window.location.host.indexOf('192.168') !== -1 ||
      window.location.hostname.indexOf('azurewebsites.net') === -1
    ) setCookie({ name: 'DEV_companyName', value: data.companyName, props: { expires } });
    // document.cookie = 'DEV_companyName=' + data.companyName + '; path=/;' + expires + '; samesite=None; secure';
    if (data.companyName) document.body.classList.add(data.companyName.toLowerCase());
    data.captchaValue
    let requestData: { [s: string]: any } = { siteName: data.companyName, userName: data.user, password: data.password };

    if (data.captchaId) {
      requestData.captchaId = data.captchaId;
      requestData.captchaValue = data.captchaValue;
    }

    // проверка входа по номеру телефона и изменение модели данных для отправки
    const selectedProvider: ILoginProvider = yield* select(getSelectedLoginProvider);
    if (selectedProvider && (selectedProvider.id === loginProvidersOnWeb.otpbysms || selectedProvider.id === loginProvidersOnWeb.sms)) requestData = { otp: data.password };
    const response = yield* call(API.system.sagas.login, requestData) as any;
    if (checkResponseStatus(response)) {

      switch (integrations) {
        case 'chat': {
          // пост меседж для чата
          // отправляем всегда
          // если что, ловим ошибку и шлём в консоль
          try {
            window.top?.postMessage({
              event: 'login-with-token',
              loginToken: response.token.chatSettings.token
            }, '*');
            yield put(login_success({ all: i18n.t('pryaniky.login.successChat') }));
            return 0;
          } catch (error) {
            if (!response.token.chatSettings) {
              toast.error('pryaniky.login.errorChat');
            }
            console.log(error)
          }
        }
        case 'debug': {
          // post message for debug auth
          try {
            const dt = {
              DEV_companyName: data.companyName,
              authUid: response.token.user_id,
              authAt: response.token.access_token,
              [response.token.cookieName]: response.token.cookieToken
            };
            window.top?.postMessage({
              event: 'debugAuth',
              data: dt
            }, '*');
            yield put(login_success({ all: i18n.t('pryaniky.login.successChat') }));
            return 0;
          } catch (error) {
            console.log(error)
          }
        }
      }


      // авторизация для отладки.... пока так..... а там что-нибудь придумаем
      if (window.location.search.substr(1).split(/[&=]/).includes('debugAuthRedirect')) {
        yield put(login_success({ all: i18n.t('pryaniky.login.success') }));
        window.location.search.substr(1).split('&').forEach(d => {
          const [name, value] = d.trim().split('=');
          if (name === 'debugAuthRedirect') {
            // document.cookie = 'companyName=' + data.companyName + '; domain=localhost; path=/;' + expires;
            const dt = {
              DEV_companyName: data.companyName,
              authUid: response.token.user_id,
              authAt: response.token.access_token,
              [response.token.cookieName]: response.token.cookieToken
            }
            window.location.assign(value + '?' + Object.keys(dt).reduce((a, c, idx) => `${a}${idx > 0 ? '&' : ''}${c}=${dt[c as any]}`, ''));
          }
        });
      } else {
        yield put(login_set_request(false));
        if (response.needReplacementPassword) {
          window.location.assign(`/firstlogin/${data.password}/${response.userInfo.id}?userName=${data.user}`)
          return 0;
        }

        yield put(login_success({ all: i18n.t('pryaniky.login.success') }));
        setCookie({ name: 'authUid', value: response.token.user_id, props: { expires } });
        setCookie({ name: 'prn_realuser', value: response.token.user_id, props: { expires } });
        setCookie({ name: 'authAt', value: response.token.access_token, props: { expires } });
        setCookie({ name: response.token.cookieName, value: response.token.cookieToken, props: { expires } });
        setCookie({ name: response.token.cookieName, value: response.token.cookieToken, props: { expires, domain: response.userInfo.baseNetwork ? `${response.userInfo.baseNetwork?.name}.pryaniky.com` : '' } });
        setCookie({ name: 'authDisplayName', value: response.userInfo.displayName, props: { expires } });
        setCookie({ name: 'authImgId', value: response.userInfo.imgId, props: { expires } });

        const redirect = getCookie('reload-redirect');
        let url = '/';
        if (redirect) {
          url = redirect;
          setCookie({ name: 'reload-redirect', value: '', props: { expires: new Date(0) } });
        }
        // если не родительская сеть и если в урле нет /sites/{companyName} добавляет, если в урле есть то заменяет на ту сеть в которую пользователь авторизуется
        if (!/\/sites\/[\w\-!]+/.test(basename)) {
          url = getNewSites(subnetworks, data.companyName) + url;
        } else {
          url = basename.replace(/\/sites\/[\w\-!]+/, getNewSites(subnetworks, data.companyName)) + url;
        }
        console.log('redirect', redirect, 'url', url, 'subnetworks', subnetworks, 'data.companyName', data.companyName, 'getNewSites', getNewSites(subnetworks, data.companyName), 'basename', basename)
        window.location.assign(url);
      }
    } else {
      yield put(login_set_request(false));
      if (response.error_code === 1000) {
        yield put(login_error({ all: i18n.t('pryaniky.login.error.1000') }));
        return;
      }
      if (response.error_code === 1002) {
        yield put(login_error({ all: i18n.t('pryaniky.login.error.1002', { name: data.companyName }) }));
        return;
      }
      if (response.error_code === 1003) {
        yield put(login_error({ all: i18n.t('pryaniky.login.error.1003') }));
        return;
      }
      if (response.error_code === 1009) {
        yield put(login_error({ all: i18n.t('pryaniky.login.error.1009') }));
        return;
      }
      if (response.error_code === 1011) {
        yield put(login_error({ all: i18n.t('pryaniky.login.error.1011') }));
        return;
      }
      
      if (response.error_code === 2004) {
        yield put(login_error({ all: i18n.t('pryaniky.login.error.2004') }));
        return;
      }

      yield put(login_error({ all: i18n.t('pryaniky.login.error') }));
      return 0;
    }

    // const data = yield* call(getGames, action.payload);
    // yield put(gamesSetGames({
    //   isFinished: action.payload.count > data.data.length,
    //   data
    // }));
  } catch (e) {
    console.error('login error', e);
  }
}

function* rasswordResetRequest(action: ILoginPasswordReset) {
  try {
    yield put(login_set_request(true));
    const data: ILoginValues = yield* select(getLoginValues);
    const response = yield* call(API.system.sagas.pwdRemind, { siteName: data.companyName, userName: data.user });
    yield put(login_set_request(false));
    console.log(response);
    if (checkResponseStatus(response)) {
      toast.success(response.error_text);
      yield put(push(urls.login));
      return;
    } else {
      if (response.error_code === 1002) toast.error(i18n.t('pryaniky.remind.error.1002', { name: data.companyName }));
      else
        if (response.error_code === 1000) toast.error(i18n.t('pryaniky.remind.error.1000'));
        else
          toast.error(i18n.t('pryaniky.remind.error'));
    }
  } catch (error) {
    console.error('password reset error: ', error);
  }
}

function* removeCookies(action: ILoginLogoutAction) {
  try {
    const appSettings = yield* select((state: IModuledStoreType) => state.store.appSettings);
    const expires = new Date(0);
    setCookie({ name: 'authUid', value: '', props: { expires } });
    setCookie({ name: 'prn_realuser', value: '', props: { expires } });
    setCookie({ name: 'authAt', value: '', props: { expires } });
    // setCookie({ name: 'companyName', value: '', props: { expires } });
    setCookie({ name: 'DEV_companyName', value: '', props: { expires } });
    setCookie({ name: '.PRYANIKYAC', value: '', props: { expires } });
    // document.cookie = 'authUid=; path=/; samesite=None; secure;' + expires;
    // document.cookie = 'prn_realuser=; path=/; samesite=None; secure;' + expires;
    // document.cookie = 'authAt=; path=/; samesite=None; secure;' + expires;
    // document.cookie = `companyName=; path=/; samesite=None; secure; ${expires}`;
    // document.cookie = `DEV_companyName=; path=/; samesite=None; secure; ${expires}`;
    // document.cookie = `.PRYANIKYAC=; path=/; samesite=None; secure; ${expires}`;
    if (appSettings) {
      // setCookie({ name: 'companyName', value: '', props: { expires, domain: `${appSettings.siteURL}.pryaniky.com` } });
      setCookie({ name: 'DEV_companyName', value: '', props: { expires, domain: `${appSettings.siteURL}.pryaniky.com` } });
      setCookie({ name: '.PRYANIKYAC', value: '', props: { expires, domain: `${appSettings.siteURL}.pryaniky.com` } });
      // document.cookie = `companyName=; domain=${appSettings.siteURL}.pryaniky.com; path=/; samesite=None; secure; ${expires}`;
      // document.cookie = `DEV_companyName=; domain=${appSettings.siteURL}.pryaniky.com; path=/; samesite=None; secure; ${expires}`;
      // document.cookie = `.PRYANIKYAC=; domain=${appSettings.siteURL}.pryaniky.com; path=/; samesite=None; secure; ${expires}`;
      // document.cookie = `.PRYANIKYAC=; domain=${appSettings.siteURL}.app.pryaniky.com; path=/;${expires}`;
    }
    setCookie({ name: 'authDisplayName', value: '', props: { expires } });
    setCookie({ name: 'authImgId', value: '', props: { expires } });
    // document.cookie = 'authDisplayName=; path=/; samesite=None; secure; ' + expires;
    // document.cookie = 'authImgId=; path=/; samesite=None; secure; ' + expires;
    yield put(push(urls.login));
    return;
  } catch (error) {
    console.error('logout error: ', error);
  }
}

function* firstLogin(action: ILoginFirstlogin) {
  try {
    yield put(login_set_request(true));
    const { companyName, user, password, newPassword }: ILoginValues = yield* select(getLoginValues);
    const setPassrordResponse = yield* call(API.system.sagas.pwdSet, {
      companyName,
      userid: action.payload,
      oldPassword: password,
      newPassword: newPassword
    });
    yield put(login_select_provider(loginProvidersOnWeb.forms));
    yield put(login_change_field({ user, password }));
    if (checkResponseStatus(setPassrordResponse)) {
      yield put(login_change_field({ password: newPassword }));
      yield put(login());
      return;
    } else {
      toast.error(getErrorText(setPassrordResponse, 'setpwd'));
      yield put(login_set_request(false));
      return;
    }
  } catch (error) {
    console.error('first login error: ', error);
  }
}

function* passwordSet(action: ILoginPasswordSet) {
  try {
    const { companyName, password, newPassword }: ILoginValues = yield* select(getLoginValues);
    const setPassrordResponse = yield* call(API.system.sagas.pwdSet, {
      companyName,
      userid: action.payload,
      oldPassword: password,
      newPassword: newPassword
    });
    if (checkResponseStatus(setPassrordResponse)) {
      yield put(push(urls.login));
      return;
    } else {
      toast.error(getErrorText(setPassrordResponse, 'setpwd'));
    }
  } catch (error) {
    console.error('set password error: ', error);
  }
}

function* getSettigns(action: ILoginGetCompanySettingsAction | ILoginChangeField) {
  try {
    yield put(login_change_reducer_field('settingsLoading', true));
    // если изменили не поле companyName то не выполняем сагу
    if (action.type === LOGIN_CHANGE_FIELD && !action.payload.companyName) return 0;
    const dt: IResponseWithData<ILoginSettingsResponseData> = yield* call(API.system.sagas.getLoginSettings);
    if (checkResponseStatus(dt)) {
      const { companyName }: ILoginValues = yield* select(getLoginValues);
      if (!companyName && dt.data.linkedNetworks.length === 1) {
        yield put(login_set_settigns({ companyNameIsExist: 'aloneLinkedNetworks' }));
      }
      // если это первое получение данных, то нетворки берем из него, иначе в выпадашке нетворки будут перемешиваться
      if (action.type === LOGIN_GET_COMPANY_SETTINGS) {
        let { linkedNetworks, useCode, allowedDomains, autoRegistration, providers } = dt.data;
        if (!providers.map(e => e.id).includes(loginProvidersOnWeb.sms) && !providers.map(e => e.id).includes(loginProvidersOnWeb.otpbysms)) {
          delete registrationRequiredFields.phone;
        }
        // if predefined company name isn't include in linkedNetworks then 
        if (!linkedNetworks.map(el => el.name).includes(companyName)) {
          const values = yield* select(getLoginValues);
          yield put(login_set_settigns({
            values: {
              ...values,
              companyName: linkedNetworks.find(el => el.isParentNetwork)?.name || ''
            }
          }));
        }
        allowedDomains = allowedDomains?.includes('*') ? null : allowedDomains;
        yield put(login_set_subnetworks(linkedNetworks));
        yield put(registrationSetAutoregistration({
          allowedDomains,
          autoRegistration,
          useCode,
        }));
      }
      setCustomDesign(dt.data.appDesign);
      if (!window.location.search.slice(1).includes('force=true') && dt.data.autoLoginWithUser) {
        const data: ILoginValues = yield* select(getLoginValues);
        const expires = getExpires({ ...data, rememberMe: true });
        setCookie({ name: 'authUid', value: dt.data.autoLoginWithUser.loggedUserId, props: { expires } });
        setCookie({ name: 'prn_realuser', value: dt.data.autoLoginWithUser.loggedUserId, props: { expires } });
        setCookie({ name: 'authAt', value: dt.data.autoLoginWithUser.loggedSessionId, props: { expires } });
        setCookie({ name: '.PRYANIKYAC', value: dt.data.autoLoginWithUser.loggedCookie, props: { expires } });
        setCookie({ name: 'authDisplayName', value: dt.data.autoLoginWithUser.loggedUserName, props: { expires } });
        window.location.assign(basename.replace('/administration', '') + '/dash');
        return 0;
      }
      if (!window.location.search.slice(1).includes('force=true') && !window.location.search.slice(1).includes('error=') && baseProvidersRedirect.includes(dt.data.baseProvider?.id) && dt.data.providers.length === 1) {
        let toUrl = getCookie('reload-redirect');
        if (toUrl === '/') toUrl = '';
        setCookie({ name: 'reload-redirect', value: '', props: { expires: new Date(0) } });
        const matchSites = window.location.pathname.match(/\/sites\/[\w\-!]+/);
        if (matchSites) {
          toUrl = matchSites[0] + toUrl
        }
        const returnUrl = `${window.location.origin}${toUrl}`;
        let url = dt.data.baseProvider?.url?.replace(/returnUrl=.+/g, `returnUrl=${returnUrl}`);
        if (url?.indexOf('returnUrl') === -1) {
          url = url.includes('?') ? url + '&' : url + '?';
          url = url + `returnUrl=${returnUrl}`;
        }
        window.location.assign(url || '');
        return 0;
      }
      // if in url has force=true and providers not includes login provider
      if (window.location.search.slice(1).includes('force=true') && !dt.data.providers.map(e => e.id).includes(loginProvidersOnWeb.login)) {
        dt.data.providers.push({ id: loginProvidersOnWeb.login });
      }
      yield put(i18n_set_avaliable_languages(dt.data.languages));
      yield put(i18n_set_language(dt.data.defaultLanguage));
      let { providers } = dt.data;
      // если есть провайдеры с одинаковой формой(логин/пароль)
      if (
        providers.map(p => p.id).filter(p => providersWithLoginPassFrom.includes(p)).length > 1
      ) {
        // по умолчанию оставляем провайдер forms
        let providerToStay = loginProvidersOnWeb.forms;
        // если базовый провайдер из тех что повторяются, то оставляем его, а остальные из дублирующихся удаляем
        if (providersWithLoginPassFrom.includes(dt.data.baseProvider?.id)) {
          providerToStay = dt.data.baseProvider?.id;
        }
        providers = providers.filter(p => !providersWithLoginPassFrom.filter(dp => dp !== providerToStay).includes(p.id));
      }
      // set captcha data
      if (dt.data.captcha?.enabled) {
        yield put(login_change_field({ captchaId: dt.data.captcha.id }));
        yield put(login_set_captcha(dt.data.captcha));
      }
      // set appDesign
      yield put({ type: baseActions.CHANGE_STATE, payload: { propPath: 'appDesign', value: (dt.data as any).appDesign } });
      yield put({ type: AppSettingsActions.CHANGE_APP_SETTINGS, payload: dt.data });
      yield put(login_set_providers(providers.map(provider => ({ ...provider, selected: provider.id === dt.data.baseProvider?.id })).filter(provider => useLoginProvidersOnWeb.includes(provider.id))));
      return 0;
    }
  } catch (error) {
    console.error('set subnetworks error: ', error);
  } finally {
    yield put(login_change_reducer_field('settingsLoading', false));
  }
}

/**
 * отправляет запрос на отправку смс кода на указанный номер телефона
 * @param { ILoginSendSmsCodeAction } action 
 */
function* sendSmsCode(action: ILoginSendSmsCodeAction) {
  try {
    const { companyName, user }: ILoginValues = yield* select(getLoginValues);
    const smsCodeResponse: IResponseWithData<ILoginSendSmsCodeResponseData> = yield* call(API.system.sagas.getSmsCode, {
      siteName: companyName,
      sendTo: user
    });
    if (checkResponseStatus(smsCodeResponse)) {
      yield put(login_change_service({ codeSended: true, codeAlreadySended: true }));
    } else {
      if (smsCodeResponse.error_code === 1000) yield put(login_error({ all: i18n.t('pryaniky.login.error.1000') }));
      if (smsCodeResponse.error_code === 11003) yield put(login_error({ all: i18n.t('pryaniky.login.error.11003') }));
    }
    return 0;
  } catch (error) {
    console.error('send sms code error: ', error);
  }
}

/**
 * send request to check user invitation. If invitation not active redirect to /login
 * @param { ILoginCheckUserInvitationAction } action 
 */
function* checkUserInvitation(action: ILoginCheckUserInvitationAction) {
  try {
    const { userId, inv } = action.payload;
    const checkInvitationResponse = yield* call(API.system.sagas.checkActiveInvitation, userId, inv);
    if (!checkResponseStatus(checkInvitationResponse)) {
      //   toast.info(Translate.t({ i18nKey: 'pryaniky.firstlogon.checkInvitationError' }));
      yield put(push(urls.login));
      return;
    }
  } catch (error) {
    console.error('check invitation error: ', error);
  }
};

function* updateCpatcha(action: ILoginCaptchaUpdate) {
  try {
    const dt: IResponseWithData<ILoginSettingsResponseData> = yield* call(API.system.sagas.getLoginSettings);
    if (checkResponseStatus(dt)) {
      // set captcha data
      if (dt.data.captcha?.enabled) {
        yield put(login_change_field({ captchaId: dt.data.captcha.id }));
        yield put(login_set_captcha(dt.data.captcha));
      } else {
        toast.error(i18n.t('pryaniky.login.captchaupdate.error'));
      }
    }
  } catch (error) {
    console.error('update captcha error: ', error);
  }
}

export const loginSaga = function* loginSaga() {
  yield takeLeading(LOGIN_AUTHENTICATE, loginRequest);
  yield takeLeading(LOGIN_PASSWORD_RESET, rasswordResetRequest);
  yield takeEvery(LOGIN_LOGOUT, removeCookies);
  yield takeEvery(LOGIN_FIRST_LOGIN, firstLogin);
  yield takeEvery(LOGIN_PASSWORD_SET, passwordSet);
  yield takeLeading([LOGIN_GET_COMPANY_SETTINGS, LOGIN_CHANGE_FIELD], getSettigns);
  yield takeLeading(LOGIN_SEND_SMS_CODE, sendSmsCode);
  yield takeLeading(LOGIN_CHECK_USER_INVITATION, checkUserInvitation);
  yield takeLeading(LOGIN_UPDATE_CAPTCHA, updateCpatcha);
};

export default loginSaga;