import React from 'react';
import { IFeedbackProps, cnFeedback, mapFeedbackStateToProps, mapFeedbackActionsToProps, IFeedbackState } from './Feedback.index';
import { connect } from 'react-redux';
import { Spinner, Icon, Avatar, Loading, ErrorMsg } from 'uielements/src';
import './Feedback.scss';
import { i18n } from "localization";
import { Translate } from "localization";
import { Input, Textarea, Button } from 'uielements/src';
import { withRouter } from 'react-router';
import { GUID_EMPTY } from 'utils/src/constants.prn';
import PryanikyEditor from 'uielements/src/PryanikyEditorV1';
import { EditorState, convertFromRaw } from 'draft-js';
import { NodeBulder } from 'uielements/src/CommonmarkRender';
import { allEnabledPreset } from 'uielements/src/PryanikyEditorV1/modulesMap'; 

class FeedbackPresenter extends React.Component<IFeedbackProps, IFeedbackState>{

  constructor(props: IFeedbackProps) {
    super(props);
    props.feedback_get_settings();
    props.getUser(props.match.params.uId);
    this.state = {
      header: EditorState.createWithContent(convertFromRaw(new NodeBulder(props.settings.header  || '').getDraftRaw()))
    }
  }

  public componentDidMount() {
    const { getUser, authUserDisplayName, authUserId } = this.props;
    if(authUserId !== GUID_EMPTY && !authUserDisplayName) getUser(authUserId);
  }

  public componentDidUpdate(pp: IFeedbackProps) {
    const { settings } = this.props;
    if(settings.header !== pp.settings.header) this.setState({ header: EditorState.createWithContent(convertFromRaw(new NodeBulder(settings.header || '').getDraftRaw())) });
  }


  /**
   * sendFeedback
   */
  public sendFeedback = (isLike: boolean) => () => this.props.feedback({ isLike, user: this.props.match.params.uId });

  /**
   * dataIsFill
   */
  public dataIsFill = () => Boolean(this.props.values.comment && this.props.values.user && (this.props.settings.hideContactField || this.props.values.contacts))

  /**
   * render
   */
  public render() {
    const { request, values, user, settings } = this.props;
    // console.log("values",values)
    if(!settings.isEnabled) {
      return (
        <div className={cnFeedback()}>
          <div className={cnFeedback('Wrapper')}>
            {
              request
              ? <ErrorMsg type='info'>
                <Loading />
              </ErrorMsg>
              : <ErrorMsg type='warning'>
                  <Translate i18nKey='pryaniky.feedback.disabled' />
                </ErrorMsg>
            }
          </div>
        </div>
      )
    }
    return (
      <div className={cnFeedback()}>
        <div className={cnFeedback('Wrapper')}>
          {
            settings.header && 
            <div className={cnFeedback('AdditionalData')}>
              <PryanikyEditor
                // maxFileSize={this.props.maxFileSize}
                key={'no_edit_with_state'}
                enableModules={allEnabledPreset}
                enabledAnvanced={true}
                disableToolbar={true}
                setActionBtn={() => { }} value={this.state.header} onChange={() => { }}
                viewOnly={true} />
              {/* <MDRender source={settings.header} /> */}
            </div>
          }
          <form className={cnFeedback('Form')} onSubmit={this.handleSubmit}>
            {
              user &&
              <div className={cnFeedback('UserInfo')}>
                <Avatar shape={'square'} size={185} imgId={user.baseData.imgId} imgUrl={user.baseData.imgUrl} name={user.baseData.displayName} />
                <div className={cnFeedback('UserInfo-Data')}>
                  <h1 children={user.baseData.displayName} />
                  <div children={user.baseData.position} />
                  <div children={user.baseData.division} />
                </div>
              </div>
            }
            <Textarea 
              icon="edit"
              onChange={this.handleChange}
              name="comment"
              type="textarea"
              value={values.comment}
              // readOnly={additionalData.readonly && additionalData.readonly.comment}
              placeholder={i18n.t('pryaniky.feedback.comment')}
            />
            <Input
              icon="user"
              onChange={this.handleChange}
              name="user"
              value={values.user}
              readOnly={values.user !== '' && settings.readonlySenderName}
              // readOnly={additionalData.readonly && additionalData.readonly.user}
              placeholder={i18n.t('pryaniky.feedback.user')}
            />
            {
              !settings.hideContactField && 
              <Input
                icon="passport"
                onChange={this.handleChange}
                name="contacts"
                value={values.contacts}
                // readOnly={additionalData.readonly && additionalData.readonly.contacts}
                placeholder={i18n.t('pryaniky.feedback.contacts')}
              />
            }
            <div className={cnFeedback('Form-Actions')}>
              {request && <Spinner className={cnFeedback('Spinner')} fontSize={40} />}
              {
                // (!additionalData.hide || !additionalData.hide.like) &&
                <Button main disabled={!this.dataIsFill()} onClick={this.sendFeedback(true)}>
                  <Translate i18nKey='pryaniky.feedback.like' />
                  <Icon icon='thumbs-up' />
                </Button>
              }
              {
                // (!additionalData.hide || !additionalData.hide.dislike) &&
                <Button main disabled={!this.dataIsFill()} onClick={this.sendFeedback(false)}>
                  <Translate i18nKey='pryaniky.feedback.dislike' />
                  <Icon icon='thumbs-down' />
                </Button>
              }
            </div>
          </form>
        </div>
      </div>
    )
  }

  private get allFieldsFilled(): boolean {
    // const { companyName, login, password } = this.state;
    const { values } = this.props;
    return values.comment.length > 0 && values.contacts.length > 0 && values.user.length > 0;
  }

  private handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (this.allFieldsFilled) {
      this.feedback();
    } else {
      this.props.feedback_error({ all: i18n.t('pryaniky.login.notFilled') });
    }
  };

  private feedback = () => {

  }

  private handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    // const { additionalData } = this.props;
    const { name, value, type, checked } = e.currentTarget;
    // if(name && additionalData.readonly && additionalData.readonly[name]) return null;
    switch(type) {
      case 'checkbox': {
        this.props.feedback_change_field({ [name]: checked });
        break;
      }
      default: {
        this.props.feedback_change_field({ [name]: value });
      }
    }
  }
}

export const Feedback = withRouter(
  connect(
    mapFeedbackStateToProps,
    mapFeedbackActionsToProps
  )(FeedbackPresenter)
)