import React, { FC, useMemo } from 'react'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { Icon } from 'uielements/src/Icon/Icon';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { Translate } from "localization";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as utils from 'utils/src/utils';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noEditblField: {
            textDecoration: "none",
            margin: "25px 0 0 0",
            borderBottom: "1px solid #999",
            width: "100%"
        },
        noEditblFieldSpan: {
            color: "#999",
            fontSize: "13px"
        },
        noEditblFieldP: {
            margin: "0"
        },
        formControl: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
        formControlPhone: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
    })
);


export const String: FC<any> = ({ field, onChangeFields, value, errorMessage }) => {
    const classes = useStyles();
    const [email, setEmail] = React.useState({ value: value, valid: true, error: '' });
    // console.log('field', user.profileData.userInfoFields.entities.fields[fieldId])        
    const handleChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.currentTarget.value;
        // console.log("email", e.currentTarget.value)
        // if (value === "") setEmail({ value, valid: true, error: '' });
        if (!utils.isEmail(value)) setEmail({ value, valid: false, error: Translate.t({ i18nKey: 'pryaniky.modal.inviteUser.error' }) });
        if (value === "" || utils.isEmail(value)) {
            setEmail({ value, valid: true, error: '' });
            onChangeFields(field.id, 'value')(e.currentTarget.value)
        }
    };
    return (
        <FormControl fullWidth className={classes.formControlPhone} id={field.id}>
            <TextField
                error={email.error !== ''}
                id="standard-error-helper-text"
                helperText={email.error !== '' && Translate.t({ i18nKey: 'pryaniky.modal.inviteUser.error' })}
                // label={<Translate i18nKey={`pryaniky.user.fields.${field.id}`} defaults={field.name} />}
                value={email.value}
                onChange={handleChangeEmail}
                placeholder={`${Translate.t({ i18nKey: `pryaniky.user.fields.${field.id}`, defaults: field.name })}${field.isMandatory ? ' *' : ''}`}
                InputProps={{
                    endAdornment: errorMessage && <HelpTooltip help={errorMessage}><Icon icon="exclamation-circle" className={'Input-ErrorIcon'} /></HelpTooltip>
                }}
            />
        </FormControl>
    );

}

export default String