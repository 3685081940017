import { ISagaModule } from 'redux-dynamic-modules-saga';
import { store, IStore } from './base/reducer';
import { usersReducer } from './users/reducer';
import { appSettingsReducer } from './reducers/AppSettings';
import { connectRouter, RouterState } from 'connected-react-router';
import { history } from './browserHistory';
import { Reducer, AnyAction } from 'redux';
import { History } from 'history';
import { routerMiddleware } from 'connected-react-router';

interface IBaseReduxModule {
  store: IStore,
  users: ReturnType<typeof usersReducer>,
  appSettings: ReturnType<typeof appSettingsReducer>,
  router: ReturnType<Reducer<RouterState<any>, AnyAction>>
}

export function getBaseModule(): ISagaModule<IBaseReduxModule> {
  return {
    id: "base",
    middlewares: [
      routerMiddleware(history),
    ],
    reducerMap: {
      store: store,
      users: usersReducer,
      appSettings: appSettingsReducer,
      router: connectRouter(history)
    },
  };
}