import { ISagaModule } from 'redux-dynamic-modules-saga';
import { feedbackReducer as feedback } from './reducer';
import { IFeedbackReducer } from './interfaces';
import { feedbackSaga } from './saga';

// type IFeedbackModule = { feedback: IFeedbackReducer } 

const reducerMap = {
  feedback
}

type IFeedbackModule = {[s in keyof typeof reducerMap]: ReturnType<typeof reducerMap[s]>};

export type WithFeedbackModule<S = any> = S & IFeedbackModule;

export function getFeedbackModule(): ISagaModule<IFeedbackModule> {
  return {
    id: "feedback",
    reducerMap,
    sagas: [
      feedbackSaga
    ],
  };
}