import { cn } from '@bem-react/classname';
import { IModuledStoreType } from '../../redux/store';
import { bindActionCreators, Action, Dispatch } from "redux";
import { RouteComponentProps } from 'react-router';
import { getLoginValues, getLoginErrors, getLogin } from '../../redux/login/selectors';
import { setBackgroundImg } from '../../redux/base/actions';
import { login_change_field, login_error, login_error_clear, login_password_reset, login_get_company_settings } from '../../redux/login/actions';

export const cnForgot = cn('Forgot');

export interface IForgotProps extends IForgotOwnProps, IMapForgotStateToProps, IMapForgotActionsToProps {};

export interface IForgotOwnProps extends RouteComponentProps<IForgotRouteProps> {};

export interface IForgotRouteProps {
  uId: string
}

export const mapForgotStateToProps = (state: IModuledStoreType, props: IForgotOwnProps) => ({
  values: getLoginValues(state),
  errors: getLoginErrors(state),
  request: getLogin(state).request,
  subnetworks: getLogin(state).subnetworks,
});

export type IMapForgotStateToProps = ReturnType<typeof mapForgotStateToProps>;

export const mapForgotActionsToProps = (dispatch: Dispatch<Action>, ownProps: IForgotOwnProps) => bindActionCreators({
  login_error,
  login_error_clear,
  login_password_reset,
  setBackgroundImg,
  login_change_field,
  login_get_company_settings,
}, dispatch);

export type IMapForgotActionsToProps = ReturnType<typeof mapForgotActionsToProps>;