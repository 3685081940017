import React, { FC, useMemo } from 'react'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { Icon } from 'uielements/src/Icon/Icon';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { Translate } from "localization";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as utils from 'utils/src/utils';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import moment from 'moment';
import { createDateFromMask, getLocaleDateMask, isValidDate } from 'utils/src/utils.dates';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noEditblField: {
            textDecoration: "none",
            margin: "25px 0 0 0",
            borderBottom: "1px solid #999",
            width: "100%"
        },
        noEditblFieldSpan: {
            color: "#999",
            fontSize: "13px"
        },
        noEditblFieldP: {
            margin: "0"
        },
        formControl: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
    })
);


export const DateTime: FC<any> = ({ field, onChangeFields, value, errorMessage }) => {
    const classes = useStyles();
    // const mask = field.mask || "dd/MM/yyyy HH:mm"
    // const dateValue = value ? utils.createDateFromMask(value, mask) : null

    // проверка осталась что бы не падало на старых данных
    if(value && value.indexOf('/') !== -1) value = createDateFromMask(value, field.mask).toISOString();

    // используем момент для форматирования даты по текущей локали
    const format = value ? moment(value).format('L LT') : undefined;

    // ручную введенную дату парсим сами так как компонент KeyboardDatePicker похоже не умеет работать с другими локалями кроме en
    // маска для момента, чтобы из строки сделать дату ,если пользователь ввел дату руками
    const maskToParseString = useMemo(() => getLocaleDateMask(true), []);

    // маска для инпута, если пользователь вводит дату руками
    const charMask = '_';
    const inputMask = useMemo(() => moment().format('L LT').replace(/\d/g, '_'), []);

    const [helperText, setHelperText] = React.useState<React.ReactNode | null>(null);

    return (
        <FormControl fullWidth key={field.id} id={field.id} component="fieldset" className={classes.formControl}>
            <KeyboardDateTimePicker
                clearable
                autoOk
                id={`datetime-` + field.id}
                variant="inline"
                // label={<Translate i18nKey={`pryaniky.user.fields.${field.id}`} defaults={field.name} />}
                placeholder={`${Translate.t({ i18nKey: `pryaniky.user.fields.${field.id}`, defaults: field.name })}${field.isMandatory ? ' *' : ''}`}
                // onError={console.log}
                disablePast
                // формат отображения даты
                format={format}
                // маска ввода для инпута
                mask={inputMask}
                // маска для символа при введении руками, определена стандартная, что бы было понятнее как парсить дальше
                maskChar={charMask}
                InputAdornmentProps={{ position: "start" }}
                value={value}
                ampm={false}
                helperText={helperText}
                error={helperText !== null}
                onError={(error) => {
                    if(error !== '') setHelperText(error);
                }}
                onChange={(dateTime, val) => {
                    let isValid = isValidDate(dateTime);
                    if(!isValid && val && val.indexOf(charMask) == -1) {
                        // устанавливаем dateTime относительно маски
                        dateTime = moment(val, maskToParseString).toDate();
                        isValid = isValidDate(dateTime);
                    }
                    if(isValid) {
                        onChangeFields(field.id, 'value')(dateTime?.toISOString());
                        setHelperText(null);
                    } else {
                        setHelperText(<Translate i18nKey={'pryaniky.datepicker.error'} />);
                    }
                }}
                InputProps={{
                    endAdornment: errorMessage && <HelpTooltip help={errorMessage}><Icon icon="exclamation-circle" className={'Input-ErrorIcon'} /></HelpTooltip>
                }}
            />
        </FormControl >
    );
}

export default DateTime