export const noop = '';
const isIE = /*@cc_on!@*/false || !!(document as any).documentMode;
if (isIE) {
    require('./forIE.js');
    require('react-app-polyfill/ie9');
    require('react-app-polyfill/stable');
    require('proxy-polyfill');
}
require('abortcontroller-polyfill/dist/polyfill-patch-fetch');

if (!Object.values) {
    Object.values = function(obj: any) {
        return Object.keys(obj).reduce((a, c) => [...a, obj[c]], [] as any[]);
    }
}

if (!Object.entries) {
    Object.entries = function(obj: any){
      var ownProps = Object.keys( obj ),
          i = ownProps.length,
          resArray = new Array(i); // preallocate the Array
      while (i--)
        resArray[i] = [ownProps[i], obj[ownProps[i]]];
  
      return resArray;
    };
}

declare global {
    interface ObjectConstructor {
        typedKeys<T>(obj: T): Array<keyof T>
    }
}
Object.typedKeys = Object.keys as any