import { IAppDesign } from "utils/src/requests/models/api.system";
import { hexToHSL } from "utils/src/utils.colors";


export const setCustomDesign = (data: IAppDesign) => {
    const domElems = [document.getElementById('customCss'), document.getElementById('customJs')];
    domElems.forEach(el => el ? el.remove() : undefined);
  
    const style = document.createElement('style');
    style.setAttribute('id', 'customCss');
    style.setAttribute('type', 'text/css');
    style.innerText = generateCssFromMainColor(data.colors);
    style.innerText += data.customCss;
    document.body.append(style);
  
    const scr = document.createElement('script');
    scr.setAttribute('id', 'customJs');
    scr.innerText = data.customJs;
    // document.body.append(scr);
  }

type IgenerateCssFromMainColorDataKeys = 'primaryColor1' | 'primaryColor2' | 'primaryColor3' | 'additionalColor1' | 'additionalColor2' | 'backgroundColor' | 'backgroundImageUrl';
type IgenerateCssFromMainColorData = {
  [key in IgenerateCssFromMainColorDataKeys]?: string;
}

export const generateCssFromMainColor = (data: IgenerateCssFromMainColorData) => {
  if (!data) return '';
  const css = Object.keys(data).reduce((css, k: any) => {
    const key = k as IgenerateCssFromMainColorDataKeys;
    if (!data[key]) return css;
    switch (key) {
      case 'primaryColor1': {
        // Статичное левое меню
        css += `.LeftMenu { background-color: ${data[key]}; } `;
        // Строка при выборе на фильтрах
        css += `.Selectize-Button_active { background-color: ${data[key]}; } `;
        // Строка при наведении в списках (раздел с группами, раздел с ДР и другие подобные разделы)
        css += `.List-Item_type_common:hover, .List-Item_type_common:hover .Group-Label, .List-Item_type_common:hover .List-Item-DataPart a.Button,.Suggesters-Results .Item:hover { background-color: ${data[key]};} `;
        // Цвет кошельков в каталоге товаров
        css += `.Shop-Header-Search-Wallet, .ShopCard-Price-Purse, .ShopCard-Price-Purse-Img { background-color: ${data[key]}; } `;
        // Иконки в шапке портала
        css += `.Header-Button-Icon { color: ${data[key]}; } `;

        css += `.CustomStyle .primaryColor1-text { color: ${data[key]}; } `;
        css += `.CustomStyle .primaryColor1-bg { background-color: ${data[key]}; } `;
        css += `.CustomNode-Header, .CustomNode-HeaderWithoutUsers { background-color: ${data[key]}; } `;
        css += `.Virtcurrency-Icon,  .Virtcurrency-IconColl, .ShopWidget-Icon,  .QuestItem-Rewards-Item-Icon { background-color: ${data[key]}} `;
        break;
      }
      case 'primaryColor2': {
        // Строка при наведении в левом меню
        css += `.LeftMenu-Item_open, .LeftMenu-Item_active, .LeftMenu-Item:hover, .LeftMenuItem-Layout_expanded .LeftMenuItem-Body, .LeftMenuItem-Body:hover { background-color: ${data[key]}; } `;
        // styles to mui5 left menu
        css += `.LeftMenuItemMui:hover, .LeftMenuItemMui_selected { background-color: ${data[key]}; }`
        // Строка при наведении на фильтрах
        css += `.Selectize-Button:hover:not(.Selectize-Button_active) { background-color: ${data[key]}; } `;
        // Выбранный элемент в выпадающем списке (настройки оповещений в группе)
        css += `.SplitButton-Item .Button_main { background-color: ${data[key]}; } `;
        // Выбранный элемент-иконка в шапке портала
        // ????
        css += `.CustomStyle .primaryColor2-text { color: ${data[key]}; } `;
        css += `.CustomStyle .primaryColor2-bg { background-color: ${data[key]}; } `;
        break;
      }
      case 'primaryColor3': {
        // Активные кнопки для отправки
        css += `.Button_main { background-color: ${data[key]}; } `;
        // Кнопки сохранения
        css += `.MuiButton-containedPrimary { background-color: ${data[key]}; } `;
        // Выбранная вкладка на форме отправки
        css += `.CreateNews .PriorityNav-Button_active { border-bottom-color: ${data[key]}; } `;
        // Ссылки в постах, кликабельные ФИО сотрудников, на виджетах, в т.ч. ссылка "Сколько перечислить в благодарность?"
        // css += `.Button_type_link:visited, .Button_type_rlink:visited, .Button_type_link, .Button_type_rlink { color: ${data[key]}; }`;
        // css += `.Button_type_link:not(.Button_main), .Button_type_rlink:not(.Button_main), .ModalDialogActions-CancelButton, .SecretSantaWidget-SaveButton-Unparticipate, .SecretSantaPart-SaveButton-Unparticipate  { color: ${data[key]}; }`;
        css += `.Button_type_link:not(.Button_main), .Button_type_rlink:not(.Button_main), .List_users_badge .List-Item:hover :not(.Dropdown):not(.Dropdown-List) .Button, .ModalDialogActions-CancelButton, .SecretSantaWidget-SaveButton-Unparticipate, .SecretSantaPart-SaveButton-Unparticipate, .Button.CommonmarkRender-ThanksMore   { color: ${data[key]}; }`;
        css += `.Button_main.Button_type_link, .Button_main.Button_type_rlink {  background-color: ${data[key]}; }`;
        css += `.BadgeIcon .Bable, .BadgeIconNew .Bable { border: 1px solid ${data[key]}; color: ${data[key]}; }`;
        // Виджет кошелька +
        // Количество валюты +
        // Цена товаров на виджете
        css += `.Virtcurrency-Count, .Virtcurrency-Icon, .ShopWidget-Price, .SurveyWidget-Navigate-Item { color: ${data[key]}; } `;

        css += `.CustomStyle .primaryColor3-text { color: ${data[key]}; } `;
        css += `.CustomStyle .primaryColor3-bg { background-color: ${data[key]}; } `;
        css += `.CustomStyle .primaryColor3-bg .Mui-selected { background-color: ${hexToHSL(data[key] || '', -10)}; } `;
        break;
      }
      case 'additionalColor1': {
        // Заливка Конкурсов +
        // Заливка Событий +
        // Заливка Идей
        // Тексты в левом меню
        css += `.NewsView-Body_type_competitions, .NewsView-Body_type_events, .NewsView-Body_type_ideas, .NewsView.type-23 .Events-Layout, .NewsView.type-21 .Ideas-Layout, .NewsView.type-6 .Creativetasks-Layout { background-color: ${data[key]}; } `;
        
        css += `.CustomStyle .additionalColor1-text { color: ${data[key]}; } `;
        css += ` .Virtcurrency-IconColl { color: ${data[key]}; } `;
        css += `.Virtcurrency-Icon .MuiSvgIcon-colorAction, .ShopWidget-Icon .MuiSvgIcon-colorAction, .QuestItem-Rewards-Item-Icon .MuiSvgIcon-colorAction { color: ${data[key]}; } `;
    
        
        css += `.CustomStyle .additionalColor1-bg { background-color: ${data[key]}; } `;

        css += `.LeftMenu .LeftMenuItem-Name, .LeftMenu .LeftMenuItem-Label { color: ${data[key]}; }`;
        // css += `.LeftMenu .Button.Button_type_link:visited, .LeftMenu .Button.Button_type_rlink:visited { color: ${data[key]}; }`;
        break;
      }
      case 'additionalColor2': {
        // Элементы на публикации конкурса
        // Элементы на публикации события
        // Элементы на публикации идеи
        css += `.NewsView.type-6 .StageBar { border-color: ${data[key]}; }`;
        css += `.NewsView.type-21 .StageBar { border-color: ${data[key]}; }`;
        css += `.NewsView.type-6 .StageBar .ButtonBox .Button { background-color: ${data[key]}; }`;
        css += `.NewsView.type-21 .StageBar .ButtonBox .Button { background-color: ${data[key]}; }`;

        css += `.CustomStyle .additionalColor2-text { color: ${data[key]}; } `;
        css += `.CustomStyle .additionalColor2-bg { background-color: ${data[key]}; } `;
        break;
      }
      case 'backgroundColor': {
        // цвет заднего фона
        css += `body { background-color: ${data[key]}; }`;
        break;
      }
      // нужно поправить ссылку на сервере
      case 'backgroundImageUrl': {
        css += `.Background { background-image: url("${data[key]}")!important; }`;
        break;
      }
    }
    return css;
  }, '');
  return css;
}