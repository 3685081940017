import { IModuledStoreType } from "../store";


/**
 * get feedback store state
 * @param { IModuledStoreType } state 
 */
export const getFeedback = (state: IModuledStoreType) => state.feedback;

/**
 * get feedback store values property
 * @param { IModuledStoreType } state 
 */
export const getFeedbackValues = (state: IModuledStoreType) => getFeedback(state).values;

/**
 * get feedback store errors property
 * @param { IModuledStoreType } state 
 */
export const getFeedbackErrors = (state: IModuledStoreType) => getFeedback(state).errors;

/**
 * get feedback settings values
 * @param { IModuledStoreType } state 
 */
export const getFeedbackSettings = (state: IModuledStoreType) => getFeedback(state).settings;