import { constants } from 'utils/src/constants.prn';
import { ACTION } from '../actionsTypes';
import { AnyAction } from 'redux';

export interface IStoreToggle extends AnyAction {
  payload: {
    variable: string;
    value?: boolean;
  }
}

let actions = {
  WINDOW_FOCUS: '',
  WINDOW_BLUR: '',
  TOGGLE: '',
  SET_AUTH_USER: '',
  CLEAR_AUTH_USER: '',
  CHANGE_STATE: '',
  SET_THANKS_FOR_ALL: '',
  MINUS_THANKS_FOR_ALL: '',
  GOT_USER_INFO: '',
  GOT_INFO: '',
  SET_NOTIFICATION_COUNT: '',
  INCREMENT_NOTIFICATION: '',
  RESET_NOTIFICATION: '',
  CHANGE_DESIGN: '',
  CHANGE_CONTEXT: '',
  SET_BACKGROUND: '',
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.STORE) }), {} as typeof actions);