import { IModuledStoreType } from "../store";

/**
 * get registration current state
 * @param { IModuledStoreType } state - current state
 */
export const getRegistration = (state: IModuledStoreType) => state.registration;

/**
 * get registration Field value by field name
 * @param state 
 * @param name 
 */
export const getRegistrationFieldByName = (state: IModuledStoreType, name: keyof ReturnType<typeof getRegistration>) => getRegistration(state)[name];

/**
 * get registration fileds from current state
 * @param { IModuledStoreType } state - current state
 */
export const getRegistrationFileds = (state: IModuledStoreType) => getRegistration(state).fields;

/**
 * get registration fileds errors from current state
 * @param { IModuledStoreType } state - current state
 */
export const getRegistrationFiledsErrors = (state: IModuledStoreType) => getRegistration(state).errors;


/**
 * get autoregistration fileds from current state
 * @param { IModuledStoreType } state - current state
 */
 export const getRegistrationAutoregistration = (state: IModuledStoreType) => getRegistration(state).autoregistration;

 /**
  * get registration reuired fileds from current state
  * @param { IModuledStoreType } state - current state
  */
  export const getRegistrationReuiredFields = (state: IModuledStoreType) => getRegistration(state).reqiredFields;