import { GUID_EMPTY } from 'utils/src/constants.prn';
import { AnyAction } from 'redux';
import actions, { IStoreToggle } from './actionsTypes';
// import actionsGroup from '../actionsTypes/Groups';
import { getCookie, setNested } from 'utils/src/utils';
import { IAction } from 'redux/actionsTypes';
import { IAppDesign } from 'utils/src/requests/models/api.system';

export interface IStore {
  auth: boolean,
  loading: boolean,
  edit: boolean,
  authUser?: any,
  type: 'prod' | 'debug' | string,
  disabledThanks: boolean,
  authUid: string,
  appSettings: { [s: string]: any } | null,
  appDesign: IAppDesign,
  groups: { [s: string]: any } | null,
  notificationCount: number;
  chatSettings: any;
  [s: string]: any;
  postTypes: IPostTypes[]
}

export interface IPostTypes {
  oldType: string,
  type: string
}

export const appDesign: IAppDesign = {
  backgroundUrl: '',
  headerUrl: '',
  logoUrl: '',
  title: '',
  customCss: '',
  customJs: '',
}

const defaultStore: IStore = {
  auth: false,
  loading: true,
  edit: window.location.search.slice(1) === 'edit=true',
  type: 'Debug',
  // authUser: {},
  disabledThanks: false,
  authUid: getCookie('authUid') || GUID_EMPTY,
  appSettings: null,
  appDesign,
  groups: null,
  notificationCount: 0,
  chatSettings: {},
  postTypes: [
    {
      oldType: 'achievements',
      type: 'achievement'
    },
    {
      oldType: 'thanks',
      type: 'thanks'
    },
    {
      oldType: 'news',
      type: 'news'
    },
    {
      oldType: 'polls',
      type: 'poll'
    },
    {
      oldType: 'creativetasks',
      type: 'competition'
    },
    {
      oldType: 'badges',
      type: 'badge'
    },
    {
      oldType: 'ideas',
      type: 'idea'
    },
    {
      oldType: 'events',
      type: 'event'
    },
    {
      oldType: 'notices',
      type: 'notice'
    }
  ],
}

export const store = (state: IStore = defaultStore, action: AnyAction): IStore => {
  switch (action.type) {

    case actions.TOGGLE:
      const { variable: vart, value: valt } = (action as IStoreToggle).payload;
      return {
        ...state,
        [vart]: valt !== undefined ? valt : !state[vart]
      };

    case actions.SET_AUTH_USER:
      const { payload: authUid } = (action as IAction<string>);
      return {
        ...state,
        authUid
      };

    case actions.CLEAR_AUTH_USER:
      return {
        ...state,
        authUid: GUID_EMPTY
      };

    case actions.CHANGE_STATE:
      return {
        ...state,
        ...setNested(state, action.payload.propPath, action.payload.value),
      };

    case actions.SET_BACKGROUND: {
      return {
        ...state,
        appDesign: {
          ...(state.appDesign || {}),
          backgroundUrl: action.payload
        }
      }
    }

    // case actions.SET_THANKS_FOR_ALL:
    //   return {
    //     ...state,
    //     authUid: 
    //     // authUser: {
    //     //   ...state.authUser,
    //     //   extData: {
    //     //     ...state.authUser,
    //     //     thanksForAll: action.payload
    //     //   }
    //     // }
    //   };
    // case actions.MINUS_THANKS_FOR_ALL:
    //   return {
    //     ...state,
    //     authUser: {
    //       ...state.authUser,
    //       extData: {
    //         ...state.authUser.extData,
    //         thanksForAll: state.authUser.extData.thanksForAll - action.payload
    //       }
    //     }
    //   };
    // case actions.GOT_USER_INFO:
    //   state.userMentions[action.payload.id] = action.payload.data;
    //   return { ...state };
    // case actionsGroup.GOT_INFO:
    //   if (!state.groups) state.groups = {}
    //   state.groups[action.payload.id] = action.payload;
    //   return { ...state };
    case actions.SET_NOTIFICATION_COUNT:
      return { ...state, notificationCount: action.payload };
    case actions.INCREMENT_NOTIFICATION:
      state.notificationCount++;
      return { ...state };
    case actions.RESET_NOTIFICATION:
      state.notificationCount = 0;
      return { ...state };
    case actions.CHANGE_DESIGN:
      const { variable, value: vValue } = action.payload;
      return {
        ...state,
        appDesign: {
          ...state.appDesign,
          [variable]: vValue
        }
      };
    case actions.CHANGE_CONTEXT:
      return state;
    // const { paths, innerPath, value } = action.payload;
    // paths.forEach((path: string) => {
    //   setNested(state.context, `${path}.${innerPath}`, value);
    // });
    // state.context = JSON.parse(JSON.stringify(state.context)); // udate object reference so connected components would update too
    // return { ...state };
    default:
      return state
  }
}

// export store = (state = initialState, action: AnyAction) => {
//   switch (action.type) {
//     case 'CHANGE_STATE':
//       return {
//         ...state,
//         ...setNested(state, action.payload.propPath, action.payload.value),
//       };
//     case 'SET_THANKS_FOR_ALL':
//       return {
//         ...state,
//         authUser: {
//           ...state.authUser,
//           extData: {
//             ...state.authUser,
//             thanksForAll: action.payload
//           }
//         }
//       };
//     case 'MINUS_THANKS_FOR_ALL':
//       return {
//         ...state,
//         authUser: {
//           ...state.authUser,
//           extData: {
//             ...state.authUser.extData,
//             thanksForAll: state.authUser.extData.thanksForAll - action.payload
//           }
//         }
//       };
//     case 'GOT_USER_INFO':
//       state.userMentions[action.payload.id] = action.payload.data;
//       return { ...state };
//     case actionsGroup.GOT_INFO:
//       state.groups[action.payload.id] = action.payload;
//       return { ...state };
//     case 'SET_NOTIFICATION_COUNT':
//       return { ...state, notificationCount: action.payload };
//     case 'INCREMENT_NOTIFICATION':
//       state.notificationCount++;
//       return { ...state };
//     case 'RESET_NOTIFICATION':
//       state.notificationCount = 0;
//       return { ...state };
//     case CHANGE_DESIGN:
//       const { variable, value: vValue } = action.payload;
//       return {
//         ...state,
//         appDesign: {
//           ...state.appDesign,
//           [variable]: vValue
//         }
//       };
//     case 'CHANGE_CONTEXT':
//       const { paths, innerPath, value } = action.payload;
//       paths.forEach((path: string) => {
//         setNested(state.context, `${path}.${innerPath}`, value);
//       });
//       state.context = JSON.parse(JSON.stringify(state.context)); // udate object reference so connected components would update too
//       return { ...state };
//     default:
//       return state;
//   }
// };