import { IFeedbackActions } from "./actions.index";
import { IFeedbackReducer, IFeedbackValues } from "./interfaces";
import { FEEDBACK_CHANGE_FIELD, FEEDBACK_ERROR, FEEDBACK_ERROR_CLEAR, FEEDBACK_SET_REQUEST, FEEDBACK_VALUES_CLEAR, FEEDBACK_SET_ADDITIONAL_DATA, FEEDBACK_PUT_SETTINGS } from "./actions";

const baseValues: IFeedbackValues = {
  comment: '',
  user: '',
  contacts: '',
  files: []
}

const baseState: IFeedbackReducer = {
  request: false,
  values: baseValues,
  errors: {},
  settings: {
    header: '',
    isEnabled: false,
    headerHtml: '',
    hideContactField: false,
    readonlySenderName: false
  }
}


export const feedbackReducer = (state = baseState, action: IFeedbackActions): IFeedbackReducer => {
  switch(action.type) {
    case FEEDBACK_ERROR: {
      return {
        ...state,
        errors: {
          ...state.errors,
          ...action.payload
        }
      }
    }
    case FEEDBACK_ERROR_CLEAR: {
      return {
        ...state,
        errors: {}
      }
    }
    case FEEDBACK_SET_REQUEST: {
      return {
        ...state,
        request: action.payload !== undefined ? action.payload : !state.request
      }  
    }
    case FEEDBACK_CHANGE_FIELD: {
      return {
        ...state,
        values: {
          ...state.values,
          ...action.payload
        }
      }
    }
    case FEEDBACK_VALUES_CLEAR: {
      return {
        ...state,
        values: baseValues
      }
    }
    case FEEDBACK_PUT_SETTINGS: {
      return {
        ...state,
        settings: action.payload
      }
    }
    default:
      return state;
  }
}