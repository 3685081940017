import { cn } from '@bem-react/classname';
import { IModuledStoreType } from '../../redux/store';
import { bindActionCreators, Action, Dispatch } from "redux";
import { RouteComponentProps } from 'react-router';
import { getLoginValues, getLoginErrors, getLogin } from '../../redux/login/selectors';
import { login_change_field, login_error, login_error_clear, login_first_login, login_password_set, login_check_user_invitation, login_get_company_settings } from '../../redux/login/actions';
import { setBackgroundImg } from '../../redux/base/actions';
import { getUser } from '../../redux/users/actions';
import { getUserById } from '../../redux/users/selectors';

export const cnReset = cn('Reset');

export interface IResetProps extends IResetOwnProps, IMapResetStateToProps, IMapResetActionsToProps {};

export interface IResetOwnProps extends RouteComponentProps<IResetRouteProps> {};

export interface IResetRouteProps {
  password: string;
  uId: string
}

export const mapResetStateToProps = (state: IModuledStoreType, props: IResetOwnProps) => ({
  values: getLoginValues(state),
  errors: getLoginErrors(state),
  request: getLogin(state).request
});

export type IMapResetStateToProps = ReturnType<typeof mapResetStateToProps>;

export const mapResetActionsToProps = (dispatch: Dispatch<Action>, ownProps: IResetOwnProps) => bindActionCreators({
  login_error,
  login_error_clear,
  login_change_field,
  login_first_login,
  login_password_set,
  login_check_user_invitation,
  setBackgroundImg,
  login_get_company_settings
}, dispatch);

export type IMapResetActionsToProps = ReturnType<typeof mapResetActionsToProps>;