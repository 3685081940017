import { IAutoregistration } from "utils/src/requests/models/api.system";
import { IRegistrationActions, IRegistrationChangeFiledActionPayload, IRegistrationChangeErrorsActionPayload, IRegistrationChangeReducerFieldActionPayload } from "./actions.index";

export const REGISTRATION_CHANGE_FIELD = 'REGISTRATION_CHANGE_FIELD';

export function registrationChangeField(payload: IRegistrationChangeFiledActionPayload): IRegistrationActions {
  return {
    type: REGISTRATION_CHANGE_FIELD,
    payload
  }
}


export const REGISTRATION_CHANGE_ERRORS = 'REGISTRATION_CHANGE_ERRORS';

export function registrationChangeErrors(payload: IRegistrationChangeErrorsActionPayload): IRegistrationActions {
  return {
    type: REGISTRATION_CHANGE_ERRORS,
    payload
  }
}


export const REGISTRATION_SUBMIT = 'REGISTRATION_SUBMIT';

export function registrationSubmit(): IRegistrationActions {
  return {
    type: REGISTRATION_SUBMIT
  }
}

export const REGISTRATION_SET_AUTOREGISTRATION = 'REGISTRATION_SET_AUTOREGISTRATION';

export function registrationSetAutoregistration(payload: IAutoregistration): IRegistrationActions {
  return {
    type: REGISTRATION_SET_AUTOREGISTRATION,
    payload
  }
}

export const REGISTRATION_CHANGE_REDUCER_FIELD = 'REGISTRATION_CHANGE_REDUCER_FIELD';

export function registrationChangeReducerField(payload: IRegistrationChangeReducerFieldActionPayload): IRegistrationActions {
  return {
    type: REGISTRATION_CHANGE_REDUCER_FIELD,
    payload
  }
}