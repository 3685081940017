import React, { useEffect, useMemo } from 'react';
import { connect } from 'react-redux';
import { cnLoginForm, ILoginFormProps, mapLoginFormActionToProps, mapLoginFormStateToProps } from './LoginForm.index';
import './LoginForm.scss';
import { Translate } from 'localization';
import { Button, CheckboxInput, Select, Input, TickTimer, Icon, Spinner, Tabs } from 'uielements/src';
import MDRender from 'uielements/src/CommonmarkRender';
import * as utils from 'utils/src/utils';
import { loginProvidersOnWeb } from '../../../../constants';
import { IOption } from 'uielements/src/Select/Select.index';
import { phoneMaskFunc } from 'utils/src/utils';
import { ISubnetwork } from 'utils/src/requests/models/api.system';
import { ErrorMsg } from 'uielements/src/ErrorMsg/ErrorMsg';
import queryString from 'query-string';

const LoginFormPresenter: React.FC<ILoginFormProps> = ({
    values,
    service,
    errors,
    success,
    companyNameIsExist,
    integrations,
    request,
    subnetworks,
    providers,
    selectedProvider,
    captcha,
    settingsLoading,
    disableUserAccess,
    login,
    login_error,
    login_change_field,
    setBackgroundImg,
    login_error_clear,
    login_get_company_settings,
    login_success,
    login_success_clear,
    login_select_provider,
    login_send_sms_sode,
    login_change_service,
    login_update_captcha,
}) => {
    useEffect(() => {
        if (errors.all) {
            setTimeout(() => {
                login_error_clear();
            }, 3000);
        }
    }, [errors.all]);

    const onBlur = () => {
        if (values.companyName !== '') {
            utils.setCookie({ name: 'DEV_companyName', value: values.companyName });
            fetch(utils.API.background.getOld().replace('.app', ''))
                .then((r) => r.blob())
                .then((d) => {
                    const { size } = d;
                    if (size > 500) {
                        setBackgroundImg(URL.createObjectURL(d));
                    }
                });
            login_get_company_settings();
        }
    };

    const allFieldsFilled = (): boolean =>
        values.companyName.length > 0 && values.user.length > 0 && values.password.length > 0;

    const handleSubmit = (ev: any) => {
        ev.preventDefault();
        if (allFieldsFilled()) {
            login();
        } else {
            login_error({ all: 'pryaniky.login.notFilled' });
        }
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        login_error_clear();
        const { name, value, type, checked } = e.currentTarget;
        if (type === 'checkbox') {
            login_change_field({ [name]: checked });
        } else {
            login_change_field({ [name]: value });
        }
    };

    const networkSelectorOnChange = (selected: IOption) => {
        login_error_clear();
        utils.setCookie({ name: 'DEV_companyName', value: selected.value });
        fetch(utils.API.background.getOld().replace('.app', ''))
            .then((r) => r.blob())
            .then((d) => {
                const { size } = d;
                if (size > 500) {
                    setBackgroundImg(URL.createObjectURL(d));
                } else {
                    setBackgroundImg('');
                }
            });
        const selectedNetwork = selected as any as ISubnetwork;
        let redirectLink = '/login';
        if (!selectedNetwork.isParentNetwork) {
            redirectLink = `/sites/${selectedNetwork.name}${redirectLink}`;
        }
        if ((window as any).redirectPageAfterLoginNetworksChange) {
            window.location.assign(redirectLink);
        } else {
            login_change_field({ companyName: selected.value });
        }
    };

    const selectProvider = (selected: string, e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        login_select_provider(selected);
    };

    const updateCaptcha = (e: MouseEvent) => {
        e.preventDefault();
        e.stopPropagation();
        login_update_captcha();
    };

    const captchaForm =
        (captcha && captcha.enabled && (
            <>
                <div className={cnLoginForm('CaptchaImg')}>
                    <img src={captcha.url} alt={'captchaImg'} />
                    <Button
                        type="link"
                        className={cnLoginForm('CaptchaImg-Update')}
                        id={'LOGINCAPTCHAUPDATE'}
                        onClick={updateCaptcha}
                    >
                        <Icon icon="sync-alt" />
                    </Button>
                </div>
                <Translate i18nKey={'placeholder:pryaniky.login.captcha'}>
                    <Input
                        className={cnLoginForm('Captcha')}
                        id={'LOGINCAPTCHA'}
                        autoComplete="off"
                        onChange={handleChange}
                        type={'text'}
                        name="captchaValue"
                        value={values.captchaValue}
                    />
                </Translate>
            </>
        )) ||
        null;

    const loginForm = (
        <>
            <Translate i18nKey={'placeholder:pryaniky.login.userName'}>
                <Input
                    id={'LOGINUSERNAME'}
                    icon="user"
                    onChange={handleChange}
                    name="user"
                    value={values.user}
                    disabled={settingsLoading}
                />
            </Translate>
            <Translate i18nKey={'placeholder:pryaniky.login.password'}>
                <Input
                    id={'LOGINPASSWORD'}
                    icon="key"
                    onChange={handleChange}
                    type="password"
                    name="password"
                    value={values.password}
                    disabled={settingsLoading}
                />
            </Translate>
            {captchaForm}
            <Translate i18nKey={'text:remember me'}>
                <CheckboxInput
                    id={'LOGINREMEMBERME'}
                    onChange={handleChange}
                    name="rememberMe"
                    checked={values.rememberMe}
                />
            </Translate>
            <div className={cnLoginForm('Form-Actions')}>
                <Button id={'LOGINENTERBUTTON'} htmlType="submit" main>
                    <Translate i18nKey={'pryaniky.login.login'} />
                </Button>
                <Button id={'LOGINRECOVERYPASSWORD'} type="rlink" href="/forgot" noBorder noBackground>
                    <Translate i18nKey={'pryaniky.login.restorePassword'} />
                </Button>
            </div>
        </>
    );

    const sendCodeToPhone = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        login_send_sms_sode();
    };

    const onPhoneChagneSelection = (e: React.MouseEvent<HTMLInputElement>) =>
        e.currentTarget.selectionStart === 0 && (e.currentTarget.selectionStart = 1);

    const phoneHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        let { value } = e.currentTarget;
        if ('admin' === value) return;
        value = value.replace(/[-\s\(\)]/g, '');
        let phone = '';
        let mask2: any[] = [];
        value.split('').forEach((s) => {
            phone += s;
            mask2 = phoneMaskFunc(phone);
            phone = mask2
                .map((e, i) => (e instanceof RegExp ? phone[i] || (i === phone.length && phone[i - 1]) : e))
                .join('');
        });
        if (!value) {
            mask2 = phoneMaskFunc(phone);
            phone = mask2
                .map((e, i) => (e instanceof RegExp ? phone[i] || (i === phone.length && phone[i - 1]) : e))
                .join('');
        }
        login_change_field({ user: phone });
        e.type === 'focus' && !value && (e.currentTarget.selectionStart = phone.length);
    };

    const [showCode, setShowCode] = React.useState(false);

    useEffect(() => {
        if (
            selectedProvider &&
            (selectedProvider.id === loginProvidersOnWeb.sms || selectedProvider.id === loginProvidersOnWeb.otpbysms)
        ) {
            setShowCode(true);
        }
    }, [selectedProvider]);
    const toggleShowCode = (e: React.SyntheticEvent) => {
        e.preventDefault();
        e.stopPropagation();
        setShowCode(!showCode);
    };
    const smsForm = (
        <>
            <Translate i18nKey={'placeholder:pryaniky.login.phone'}>
                <Input
                    id={'LOGINUSERNAME'}
                    icon="phone"
                    autoComplete="off"
                    onFocus={phoneHandleChange}
                    onSelectCapture={onPhoneChagneSelection}
                    onChange={(e) => {
                        login_error_clear();
                        phoneHandleChange(e);
                    }}
                    name={'phone'}
                    value={values.user}
                />
            </Translate>
            {service.codeAlreadySended && (
                <Translate i18nKey={'placeholder:pryaniky.login.code'}>
                    <Input
                        className={cnLoginForm('SmsCode')}
                        id={'LOGINPASSWORD'}
                        icon="key"
                        autoComplete="off"
                        onChange={handleChange}
                        type={showCode ? 'text' : 'password'}
                        name="password"
                        value={values.password}
                    >
                        <Button
                            className={cnLoginForm('SmsCodeShow')}
                            theme={'unstyled_center'}
                            onClick={toggleShowCode}
                        >
                            <Icon icon={showCode ? 'eye-slash' : 'fa-eye'} />
                        </Button>
                    </Input>
                </Translate>
            )}
            {captchaForm}
            <Translate i18nKey={'text:remember me'}>
                <CheckboxInput
                    id={'LOGINREMEMBERME'}
                    onChange={handleChange}
                    name="rememberMe"
                    checked={values.rememberMe}
                />
            </Translate>
            <div className={cnLoginForm('Form-Actions')}>
                {service.codeAlreadySended && (
                    <Translate i18nKey={'children:pryaniky.login.login'}>
                        <Button id={'LOGINENTERBUTTON'} htmlType="submit" main />
                    </Translate>
                )}
                <Button
                    className={cnLoginForm('SmsCodeButton')}
                    disabled={service.codeSended}
                    main={!service.codeAlreadySended}
                    noPadding
                    noBackground
                    noBorder
                    onClick={sendCodeToPhone}
                >
                    {service.codeSended ? (
                        <TickTimer
                            count={60}
                            text={(tick) => (
                                <Translate
                                    i18nKey={'pryaniky.login.resendCodeTimer'}
                                    values={{ count: tick }}
                                    variablesI18nKeys={{
                                        variable: utils.nameCount(tick, 'pryaniky.variables.names.seconds', 'up'),
                                    }}
                                />
                            )}
                            end={() => login_change_service({ codeSended: false })}
                        />
                    ) : (
                        <Translate i18nKey={'pryaniky.login.sendCode'} />
                    )}
                </Button>
            </div>
        </>
    );

    const urlRedirectForm = selectedProvider && (
        <Button className={cnLoginForm('OtherAuth')} type={'link'} href={selectedProvider.url} main>
            <Translate i18nKey={'pryaniky.login.goToAuthorizationPage'} />
        </Button>
    );

    React.useEffect(() => {
        return () => {
            login_error_clear();
            login_success_clear();
        };
    }, []);

    React.useEffect(() => {
        if (!values.companyName && subnetworks.length) login_change_field({ companyName: subnetworks[0].name });
    }, [values.companyName, subnetworks.length]);

    const globalError = useMemo(() => {
        if (window.location.search.slice(1).includes('error=')) {
            const params = queryString.parse(window.location.search.slice(1));
            if (params.error) {
                return Translate.t({ i18nKey: `pryaniky.login.error.${params.error}` });
            }
        } else {
            return null;
        }
    }, []);

    return (
        <div className={cnLoginForm('Form')}>
            {(request || settingsLoading) && <Spinner className={cnLoginForm('Spinner')} fontSize={40} />}
            {success.all && (
                <div className={cnLoginForm('SuccessMessage')}>
                    <Translate i18nKey={success.all} />
                </div>
            )}
            <div className={cnLoginForm('Form-Text')} id={'LOGINWELCOMETEXT'}>
                {!!globalError && (
                    <ErrorMsg>
                        <MDRender source={globalError} />
                    </ErrorMsg>
                )}
                {disableUserAccess && (
                    <ErrorMsg>
                        <div>
                            <Translate i18nKey={'pryaniky.login.disableUserAccess.title'} />
                            {/*Доступ на портал ограничен.*/}
                        </div>
                        <div>
                            <Translate i18nKey={'pryaniky.login.disableUserAccess.description'} />
                            {/*Приносим извинения за доставленные неудобства.*/}
                        </div>
                    </ErrorMsg>
                )}
                <b>
                    <Translate i18nKey={'pryaniky.login.welcome'} />
                </b>
                <br />
                <Translate i18nKey={'source:to log in, please enter you credentials'}>
                    <MDRender source={''} />
                </Translate>
                {errors.all && (
                    <div className={cnLoginForm('ErrorMessage')}>
                        <Translate i18nKey={errors.all} />
                    </div>
                )}
            </div>
            {!companyNameIsExist && subnetworks.length <= 1 && (
                <Translate i18nKey={'placeholder:pryaniky.login.companyName'}>
                    <Input
                        id={'LOGINCOMPANYNAME'}
                        icon="passport"
                        onChange={handleChange}
                        name="companyName"
                        value={values.companyName}
                        onBlur={onBlur}
                        disabled={settingsLoading}
                    />
                </Translate>
            )}
            {subnetworks.length > 1 && (
                <Select
                    icon="passport"
                    options={subnetworks.map((el) => ({ value: el.name, ...el, title: el.title || el.name }))}
                    selected={subnetworks.reduce(
                        (a: IOption | undefined, c) =>
                            a
                                ? a
                                : c.name === values.companyName
                                ? { value: c.name, ...c, title: c.title || c.name }
                                : a,
                        undefined
                    )}
                    onChange={networkSelectorOnChange}
                />
            )}
            {providers && providers.length > 1 && (
                <div className={cnLoginForm('Providers')}>
                    <Tabs
                        tabsList={providers.map((e) => ({
                            title: <Translate i18nKey={`pryaniky.login.provider.${e.id}`} />,
                            value: e.id,
                        }))}
                        selected={selectedProvider?.id}
                        onChange={selectProvider}
                    />
                </div>
            )}
            <form onSubmit={handleSubmit}>
                {selectedProvider &&
                    (selectedProvider.id === loginProvidersOnWeb.sms ||
                        selectedProvider.id === loginProvidersOnWeb.otpbysms) &&
                    smsForm}
                {(!selectedProvider ||
                    selectedProvider.id === loginProvidersOnWeb.login ||
                    selectedProvider.id === loginProvidersOnWeb.forms ||
                    selectedProvider.id === loginProvidersOnWeb.ldap) &&
                    loginForm}
                {selectedProvider &&
                    (selectedProvider.id === loginProvidersOnWeb.adfs ||
                        selectedProvider.id === loginProvidersOnWeb.federation ||
                        selectedProvider.id === loginProvidersOnWeb.openid ||
                        selectedProvider.id === loginProvidersOnWeb.o365 ||
                        selectedProvider.id === loginProvidersOnWeb.windows) &&
                    urlRedirectForm}
            </form>
        </div>
    );
};

export const LoginForm = connect(mapLoginFormStateToProps, mapLoginFormActionToProps)(LoginFormPresenter);
