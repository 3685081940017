import { cn } from '@bem-react/classname';
import { IModuledStoreType } from '../../redux/store';
import { bindActionCreators, Action, Dispatch } from "redux";
import { getFeedback, getFeedbackSettings } from '../../redux/feedback/selectors';
import { feedback, feedback_error, feedback_change_field, feedback_get_settings } from '../../redux/feedback/actions';
import { getAuthUserId } from '../../redux/base/selectors';
import { getUserById } from '../../redux/users/selectors';
import { getUser } from '../../redux/users/actions';
import { RouteComponentProps } from 'react-router';
import { GUID_EMPTY } from 'utils/src/constants.prn';

export const cnFeedback = cn('Feedback');

export interface IFeedbackProps extends IFeedbackOwnProps, IMapFeedbackStateToProps, IMapFeedbackActionsToProps {};

export interface IFeedbackOwnProps extends RouteComponentProps<IFeedbackRouteProps> {};

export interface IFeedbackRouteProps {
  uId: string
}

export interface IFeedbackState {
  header: any;
}

export const mapFeedbackStateToProps = (state: IModuledStoreType, props: IFeedbackOwnProps) => ({
  request: getFeedback(state).request,
  errors: getFeedback(state).errors,
  values: getFeedback(state).values,
  settings: getFeedbackSettings(state),
  user: getUserById(props.match.params.uId)(state),
  authUserId: getAuthUserId(state) || GUID_EMPTY,
  authUserDisplayName: getUserById(getAuthUserId(state))(state) && getUserById(getAuthUserId(state))(state).baseData.displayName
});

export type IMapFeedbackStateToProps = ReturnType<typeof mapFeedbackStateToProps>;

export const mapFeedbackActionsToProps = (dispatch: Dispatch<Action>, ownProps: IFeedbackOwnProps) => bindActionCreators({
  feedback,
  feedback_error,
  feedback_change_field,
  feedback_get_settings,
  getUser
}, dispatch);

export type IMapFeedbackActionsToProps = ReturnType<typeof mapFeedbackActionsToProps>;