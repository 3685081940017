import { cn } from '@bem-react/classname';
import { IModuledStoreType } from '../../redux/store';
import { bindActionCreators, Action, Dispatch } from "redux";
import { i18n_set_language } from 'utils/src/CommonRedux/i18n/actions';

export interface ILanguageSelectorProps extends ILanguageSelectorOwnProps, ImapLanguageSelectorStateToProps, ImapLanguageSelectorActionsToProps {};

export interface ILanguageSelectorOwnProps {}

export const mapLanguageSelectorStateToProps = (state: IModuledStoreType, ownProps: ILanguageSelectorOwnProps) => ({
  language: state.i18n.language,
  languages: (state.store.appSettings?.languages as string[]) || state.i18n.avaliableLanguages
  // languages: ['ru', 'en']
});

export type ImapLanguageSelectorStateToProps = ReturnType<typeof mapLanguageSelectorStateToProps>;

export const mapLanguageSelectorActionsToProps = (dispatch: Dispatch<Action>, ownProps: ILanguageSelectorOwnProps) => bindActionCreators({
  i18n_set_language
}, dispatch);

export type ImapLanguageSelectorActionsToProps = ReturnType<typeof mapLanguageSelectorActionsToProps>;

export const cnLanguageSelector = cn('LanguageSelector');