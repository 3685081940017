import actions from '../actionTypes/AppSettings';

const defaultAppSettings: any = {
    companyPostTypes: []
}

export function appSettingsReducer(state = defaultAppSettings, action: any) {
    switch (action.type) {
        case actions.ADD_COMPANY_POST_TYPES: {
            return {
                ...state,
                companyPostTypes: action.payload.postTypes
            }
        }
        case actions.CHANGE_APP_SETTINGS: {
            return {
                ...state,
                ...action.payload
            }
        }

        default: return state
    }
}

