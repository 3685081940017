import React from 'react';
import { IResetProps, cnReset, mapResetStateToProps, mapResetActionsToProps } from './Reset.index';
import { Input, Button, Spinner } from 'uielements/src';
import { i18n } from "localization";
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import './Reset.scss';
import { searchObject, API, setCookie } from 'utils/src/utils';
import { Translate } from "localization";

// ошибки
// 10000: пароли не совпадают
//
//

const ResetPresenter: React.FC<IResetProps> = ({
  values,
  request,
  errors,
  match,
  location,
  login_error_clear,
  login_error,
  login_change_field,
  login_first_login,
  login_password_set,
  setBackgroundImg,
  login_check_user_invitation,
  login_get_company_settings
}) => {

  let isFirstLogon = match.url.toLowerCase().indexOf('/firstlogin') !== -1;

  React.useEffect(() => {
    login_get_company_settings();
    // set base data on did mount
    if(match.params.password && match.params.password !== 'false') login_change_field({ password: match.params.password });
    // set background on firstlogon
    if(isFirstLogon) {
      const data = searchObject(location.search);
      login_change_field({ user: data.userName });
      if (values.companyName !== '') {
        setCookie({ name: 'DEV_companyName', value: values.companyName }); 
        // document.cookie = `DEV_companyName=${values.companyName};path=/; samesite=None; secure`;
        fetch(API.background.getOld().replace('.app', ''))
          .then(r => r.blob())
          .then(d => {
            const { size } = d;
            if (size > 500) {
              setBackgroundImg(URL.createObjectURL(d));
            }
          });
      }    
    }
    // check active user invitation
    if(isFirstLogon && match.params.password && match.params.password !== 'false') login_check_user_invitation({ userId: match.params.uId, inv: match.params.password });
    return () => {
      login_error_clear();
    }
  }, []);

  React.useEffect(() => {
    if(values.newPassword && values.newPasswordConfirm && values.newPassword !== values.newPasswordConfirm) {
      login_error({ all: i18n.t('pryaniky.reset.error.10000')})
    } else {
      login_error_clear();
    }
  }, [values.newPassword, values.newPasswordConfirm]);

  const allFieldsFilled = () => values.newPassword.length > 0 && values.newPasswordConfirm.length > 0;

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (allFieldsFilled()) {
      if(isFirstLogon) {
        login_first_login(match.params.uId);
      } else {
        login_password_set(match.params.uId);
      }
    } else {
      login_error({ all: i18n.t('pryaniky.login.notFilled') });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    if (type === 'checkbox') {
      login_change_field({ [name]: checked });
    } else {
      login_change_field({ [name]: value });
    }
  };

  return (
    <div className={cnReset()}>
      <div className={cnReset('Wrapper')}>
        {request && <Spinner className={cnReset('Spinner')} fontSize={40} />}
        <form className={cnReset('Form')} onSubmit={handleSubmit}>
          <div className={cnReset('Form-Text')}>
            <h4 className="my-0">{i18n.t('pryaniky.reset.title')}</h4>
            {
              <Translate i18nKey={isFirstLogon ? 'pryaniky.firstlogon.text' : 'pryaniky.reset.text'} />
            }
            {errors.all && <div className={cnReset('ErrorMessage')}>{errors.all}</div>}
          </div>
          {
            values.user &&
            <Input
              icon="user"
              onChange={() => {}}
              name="user"
              value={values.user}
              placeholder={i18n.t('pryaniky.login.userName')}
              readOnly
            />
          }
          {
            match.params.password === 'false' &&
            <Input
              icon="key"
              type="password"
              onChange={handleChange}
              name="password"
              value={values.password}
              placeholder={i18n.t('pryaniky.reset.old')}
            />
          }
          <Input
            icon="key"
            type="password"
            onChange={handleChange}
            name="newPassword"
            value={values.newPassword}
            placeholder={i18n.t('pryaniky.reset.new')}
          />
          <Input
            icon="key"
            type="password"
            onChange={handleChange}
            name="newPasswordConfirm"
            value={values.newPasswordConfirm}
            placeholder={i18n.t('pryaniky.reset.newConfirm')}
          />
          <div className={cnReset('Form-Actions')}>
            <Button
              main={true}
              children={i18n.t(isFirstLogon ? 'pryaniky.firstlogin.submit' : 'pryaniky.reset.submit')}
              disabled={!allFieldsFilled() || (values.newPassword !== values.newPasswordConfirm)}
            />
            {/* <Button
              type="rlink"
              href="/login"
              noBorder
              noBackground
              children={i18n.t('pryaniky.reset.cancel')}
            /> */}
          </div>
        </form>
      </div>
    </div>
  )
}

export const Reset = withRouter(
  connect(
    mapResetStateToProps,
    mapResetActionsToProps
  )(ResetPresenter)
)