import actions from '../actionTypes/AppSettings';

/********************************************************/
/******************** to saga ***************************/
/********************************************************/

/************************* LOAD_COMPANY_POST_TYPES *******************************/
// export type loadCompanyPostTypesPayloadType = { groupPKID: number | string }
export type loadCompanyPostTypesActionType = { type: string/*, payload: loadCompanyPostTypesPayloadType*/ }
/**
 * запрашиваем доступные в компании типы публикаций
 */
export const loadCompanyPostTypes = (): loadCompanyPostTypesActionType => ({
    type: actions.LOAD_COMPANY_POST_TYPES,
})







/***********************************************************/
/******************** to reducer ***************************/
/***********************************************************/

/************************ ADD_COMPANY_POST_TYPES ********************************/
export type addCompanyPostTypesPayloadType = { postTypes: any }
export type addCompanyPostTypesActionType = { type: string, payload: addCompanyPostTypesPayloadType }
/**
 * добавлет доступные типы публикаций в стор
 * @param postTypes
 */
export const addCompanyPostTypes = (postTypes: any): addCompanyPostTypesActionType => ({
    type: actions.ADD_COMPANY_POST_TYPES,
    payload: { postTypes }
})