import { connect } from 'react-redux';
// import { compose, withBemMod } from '@bem-react/core';
import * as React from 'react';
import * as utils from 'utils/src/utils';
// import { phoneMaskFunc } from 'utils/src/utils';

import { cnLogin, ILoginProps, ILoginState, mapLoginStateToProps, mapLoginActionsToProps } from './Login.index';
// import { Input } from 'uielements/src/Input/Input';
// import { Button, CheckboxInput, Select, TickTimer, Icon, Spinner } from 'uielements/src';

import './Login.scss';
import { withRouter } from 'react-router';
// import { getExpires } from 'redux/login/saga';
// import { IOption } from 'uielements/src/Select/Select.index';
// import { loginProvidersOnWeb } from '../../constants';
// import PryanikyEditor, { StyleButton } from 'uielements/src/PryanikyEditorV1';
// import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
// import { mdToRaw, rawToMd } from 'uielements/src/PryanikyEditorV1/converter/convertorConfigs';
// import { allEnabledPreset } from 'uielements/src/PryanikyEditorV1/modulesMap';
// const st = '/sites/'; // sites in url

// const chatIntegrationUrl = 'ChatIntegration';
// 
import { cnFormWrapper } from './FormWrapper/FormWrapper.index';
import './FormWrapper/FormWrapper.scss';
import { LoginForm } from './Forms/Login/LoginForm';
import { RegisterForm } from './Forms/Register/RegisterForm';

import { Tabs } from 'uielements/src/Tabs/Tabs';
import { TabItem } from 'uielements/src/Tabs/Tabs.index';
import { Translate } from "localization";
import { urls } from 'routes/urls';

export class LoginPresenter extends React.Component<ILoginProps, ILoginState> {
  public utils = utils;
  public el: HTMLElement | null;
  public companyNameInUrl: boolean = false;

  constructor(props: ILoginProps) {
    super(props);
    // const data = this.utils.searchObject(this.props.location.search);
    // let companyName = '';
    // let login = '';
    // if (data.companyName) companyName = (data.companyName as string).trim();
    // if (data.login) login = (data.login).trim();
    this.props.login_get_company_settings();
    this.state = {}
  }

  public componentWillUnmount() {
    console.log('UNMOUNT!!! ');
  }

  public tabs: TabItem[] = [
    {
      title: <Translate i18nKey={'pryaniky.login.login'} />,
      value: 'login'
    },
    {
      title: <Translate i18nKey={'pryaniky.registration.registrate'} />,
      value: 'registration'
    }
  ];

  public render() {
    const { companyNameInUrl, props, state } = this;
    const { className = '', autoregistration, history, location } = props;
    // const { tab } = state;
    const tab = this.tabs.map(el => el.value).find(el => `/${el}` === location.pathname) || this.tabs[0].value;
// console.log('props', props)
    if(!autoregistration.enable && !autoregistration.enableWithTrustedDomains && location.pathname === urls.registration) return null;

    if(!autoregistration.enable && !autoregistration.enableWithTrustedDomains) {
      return (
        <div ref={el => (this.el = el)} className={cnLogin({}, [className])}>
          <div className={cnLogin('Wrapper')}>
            <div className={cnFormWrapper()}>
              <LoginForm />
            </div>
          </div>
        </div>
      )
    }
    return (
      <div ref={el => (this.el = el)} className={cnLogin({}, [className])}>
        <div className={cnLogin('Wrapper')}>
          
          <div className={cnFormWrapper()}>
            <Tabs tabsList={this.tabs} selected={tab} onChange={(selected: string) => history.push(`/${selected}`)} />
            
            {tab === 'login' && <LoginForm />}
            {tab === 'registration' && <RegisterForm />}
          </div>
          {/* <FormWrapper /> */}
        </div>
      </div>
    );
  }
}

export const Login = connect(
  mapLoginStateToProps, 
  mapLoginActionsToProps
)(
  withRouter(LoginPresenter)
)

// compose(withBemMod(cnLogin(), {}))(LoginPresenter));
