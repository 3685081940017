import React from 'react';
import { connect } from 'react-redux';
import { ILogoutProps, mapLogoutStateToProps, mapLogoutActionsToProps } from './Logout.index';

const LogoutPresenter: React.FC<ILogoutProps> = ({ login_logout }) => {

  React.useEffect(() => {
    login_logout();
  }, []);

  return null;
}

export const Logout = connect(
  mapLogoutStateToProps,
  mapLogoutActionsToProps
)(LogoutPresenter)