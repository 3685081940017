import React, { FC, useMemo } from 'react'
import StringType from '../types/String/String'
import DateType from '../types/Date/Date'
import Interests from '../types/Interests/Interests'
import StringTelefon from '../types/String_telefon/String'
import StringEmail from '../types/String_email/String'
import Tags from '../types/Tags/Tags'
import Textarea from '../types/Textarea/Textarea'
import DateTime from '../types/DateTime/DateTime'


export const FieldsRender: FC<any> = ({ field: fieldValues, onChangeFields, errorMessage }) => {
    // const dataset = fieldsSet === 'userListFields' ? user.baseData[fieldsSet].entities.fields[fieldId] : user.profileData[fieldsSet].entities.fields[fieldId]
    const { field, value, tags, files } = fieldValues;
    // if (field.displayMode === 'system') {
    //     return <></>
    // }

    if (field.fieldType === 'String' || field.fieldType === 'AdditionalUserId') {
        return <StringType onChangeFields={onChangeFields} field={field} value={value} errorMessage={errorMessage} />
    }
    if (field.fieldType === 'Date') {
        return <DateType onChangeFields={onChangeFields} field={field} value={value} errorMessage={errorMessage} />
    }
    if (field.fieldType === 'Interests') {
        return <Interests onChangeFields={onChangeFields} field={field} value={tags} errorMessage={errorMessage} />
    }
    if (field.fieldType === 'String' && field.mask === "telefon") {
        return <StringTelefon onChangeFields={onChangeFields} field={field} value={value} errorMessage={errorMessage} />
    }
    if (field.fieldType === 'Tags') {
        return <Tags onChangeFields={onChangeFields} field={field} value={tags} errorMessage={errorMessage} />
    }
    if (field.fieldType === 'Textarea') {
        return <Textarea onChangeFields={onChangeFields} field={field} value={value} errorMessage={errorMessage} />
    }
    if (field.fieldType === 'String' && field.mask === "email") {
        return <StringEmail onChangeFields={onChangeFields} field={field} value={value} errorMessage={errorMessage} />
    }
    if (field.fieldType === 'DateTime') {
        return <DateTime onChangeFields={onChangeFields} field={field} value={value} errorMessage={errorMessage} />
    }
    return <></>

}

export default FieldsRender