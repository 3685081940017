import { cn } from '@bem-react/classname';
import { IClassNameProps } from '@bem-react/core';
import { IModuledStoreType } from '../../../redux/store';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { getRegistrationAutoregistration } from '../../../redux/registration/selectors';
import { RouteComponentProps } from 'react-router';
import { login_get_company_settings } from '../../../redux/login/actions';

export const cnFormWrapper = cn('FormWrapper');

export interface IFormWrapperProps extends IFormWrapperOwnProps, IClassNameProps, ImapFormWrapperStateToProps, ImapFormWrapperActionsToProp {

};

export interface IFormWrapperOwnProps extends RouteComponentProps {

};

export const mapFormWrapperStateToProps = (state: IModuledStoreType, props: IFormWrapperOwnProps) => ({
  // autoregistration: false,
  autoregistration: getRegistrationAutoregistration(state),
});

export type ImapFormWrapperStateToProps = ReturnType<typeof mapFormWrapperStateToProps>;

export const mapFormWrapperActionsToProps = (d: Dispatch<Action>) => bindActionCreators({
  // login_get_company_settings
}, d);

export type ImapFormWrapperActionsToProp = ReturnType<typeof mapFormWrapperActionsToProps>;