import React from 'react';
import { cnForgot, IForgotProps, mapForgotStateToProps, mapForgotActionsToProps } from './Forgot.index';
import { IOption } from 'uielements/src/Select/Select.index';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { Input, Button, Select, Spinner } from 'uielements/src';
import { API, setCookie } from 'utils/src/utils';
import { i18n } from "localization";
import './Forgot.scss';

const ForgotPresenter: React.FC<IForgotProps> = ({ values, errors, subnetworks, request, setBackgroundImg, login_error, login_error_clear, login_change_field, login_password_reset, login_get_company_settings }) => {

  React.useEffect(() => {
    login_get_company_settings();
    return () => {
      login_error_clear();
    }
  }, []);

  const allFieldsFilled = () => values.companyName.length > 0 && values.user.length > 0;

  const handleSubmit = (ev: React.FormEvent) => {
    ev.preventDefault();
    if (allFieldsFilled()) {
      login_password_reset();
    } else {
      login_error({ all: i18n.t('pryaniky.login.notFilled') });
    }
  };

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, type, checked } = e.currentTarget;
    if (type === 'checkbox') {
      login_change_field({ [name]: checked });
    } else {
      login_change_field({ [name]: value });
    }
  };

  const networkSelectorOnChange = (selected: IOption) => {
    setCookie({ name: 'DEV_companyName', value: selected.value })
    // document.cookie = `DEV_companyName=${selected.value};path=/; samesite=None; secure`;
    fetch(API.background.getOld().replace('.app', ''))
      .then(r => r.blob())
      .then(d => {
        const { size } = d;
        if (size > 500) {
          setBackgroundImg(URL.createObjectURL(d));
        } else {
          setBackgroundImg('');
        }
      });
    login_change_field({ companyName: selected.value });
  }

  return (
    <div className={cnForgot()}>
      <div className={cnForgot('Wrapper')}>
        {request && <Spinner className={cnForgot('Spinner')} fontSize={40} />}
        <form className={cnForgot('Form')} onSubmit={handleSubmit}>
          <div id={'LOGINRECOVERYWELCOME'} className={cnForgot('Form-Text')}>
            <h4 className="my-0">{i18n.t('pryaniky.remind.title')}</h4>
            {i18n.t('pryaniky.remind.text')}
            {errors.all && <div className={cnForgot('ErrorMessage')}>{errors.all}</div>}
          </div>
          {
            !values.companyName && subnetworks.length <= 1 && 
            <Input
              id={'LOGINRECOVERYCOMPANYNAME'}
              icon="passport"
              onChange={handleChange}
              name="companyName"
              value={values.companyName}
              placeholder={i18n.t('pryaniky.login.companyName')}
            />
          }
          {
            subnetworks.length > 1 && 
            <Select
              icon="passport"
              options={subnetworks.map(el => ({ value: el.name, ...el, title: el.title || el.name }))}
              selected={subnetworks.reduce((a: IOption | undefined, c) => a ? a : c.name === values.companyName ? { value: c.name, ...c, title: c.title || c.name } : a, undefined)}
              onChange={networkSelectorOnChange}
            />
          }
          <Input
            id={'LOGINRECOVERYUSERNAME'}
            icon="user"
            onChange={handleChange}
            name="user"
            value={values.user}
            placeholder={i18n.t('pryaniky.login.userName')}
          />
          <div className={cnForgot('Form-Actions')}>
            <Button
              id={'LOGINRECOVERYOK'}
              main={true}
              children={i18n.t('pryaniky.remind.send')}
              disabled={!values.user || !values.companyName}
            />
            <Button
              id={'LOGINRECOVERYCANCEL'}
              type="rlink"
              href="/login"
              noBorder
              noBackground
              children={i18n.t('pryaniky.remind.cancel')}
            />
          </div>
        </form>
      </div>
    </div>
  )
}

export const Forgot = withRouter(
  connect(
    mapForgotStateToProps,
    mapForgotActionsToProps
  )(ForgotPresenter)
)