import { IModuledStoreType } from "../store";

/**
 * get users reducer state
 * @param { IModuledStoreType } state 
 */
export const getUsers = (state: IModuledStoreType) => state.users;

/**
 * get user data by user id
 * @param { IModuledStoreType } state 
 */
export const getUserById = (id: string) => (state: IModuledStoreType) => getUsers(state).users[id];