import { put, takeEvery, takeLeading, delay, takeLatest } from 'redux-saga/effects';
import { call, select } from 'utils/src/saga.effects';
import { REGISTRATION_CHANGE_FIELD, registrationChangeErrors, REGISTRATION_SUBMIT, registrationChangeReducerField } from './actions';
import { IRegistrationChangeFiledAction, IRegistrationSubmitAction } from './actions.index';
import { IRegistrationFields, IRegistrationFieldsErrors } from './interfaces';
import { getRegistrationAutoregistration, getRegistrationFileds, getRegistrationFiledsErrors, getRegistrationReuiredFields } from './selectors';
import { IValidateRule, validate } from 'utils/src/validate';
import { API, checkResponseStatus, queryStringToObj } from 'utils/src/utils';
import { toast } from 'react-toastify';
import { Translate } from "localization";
import { IAutoregistration } from 'utils/src/requests/models/api.system';
import { emailRegEx } from 'utils/src/utils.regex';

export const registrationsValidateFileldsRules: { [s: string]: IValidateRule } = {
  firstName: { 
    min: {
      value: 2
    },
    notNull: {
      value: true,
      errorMessageKey: 'pryaniky.registration.firstName.isNull'
    }
  },
  lastName: {
    min: {
      value: 2
    },
    notNull: {
      value: true,
      errorMessageKey: 'pryaniky.registration.lastName.isNull'
    }
  },
  code: {
    min: {
      value: 0
    },
    notNull: {
      value: true,
      errorMessageKey: 'pryaniky.registration.code.isNull'
    }
  },
  email: {
    regex: {
      value: emailRegEx,
      errorMessageKey: 'pryaniky.validate.email.error'
    },
    notNull: {
      value: true,
      errorMessageKey: 'pryaniky.registration.email.isNull'
    }
  },
  phone: {
    min: {
      value: 11,
      errorMessageKey: 'pryaniky.registration.phone.length'
    },
    // equal: {
    //   value: 11,
    //   errorMessageKey: 'pryaniky.registration.phone.length'
    // },
    notNull: {
      value: true,
      errorMessageKey: 'pryaniky.registration.phone.isNull'
    }
  },
  ...(window as any).AdditionalRegistrationsValidateFileldsRules
}

function* validateFields(action: IRegistrationChangeFiledAction) {
  try {
    const fields: IRegistrationFields = yield* select(getRegistrationFileds);
    if(!registrationsValidateFileldsRules[action.payload.field]) return 0;
    let errors = validate({[action.payload.field]: fields[action.payload.field]}, {[action.payload.field]: registrationsValidateFileldsRules[action.payload.field]} );
    if(action.payload.field === 'phone') {
      errors = validate({[action.payload.field]: fields[action.payload.field].replace(/[\+\(\)]/g, '')}, {[action.payload.field]: registrationsValidateFileldsRules[action.payload.field]} );
    }
    const autoregistration: ReturnType<typeof getRegistrationAutoregistration> = yield* select(getRegistrationAutoregistration);
    // проверка на разрешенные домены для почты при условии включенной саморегистрации
    if(!autoregistration.enable && autoregistration.enableWithTrustedDomains && autoregistration.allowedDomains.length && action.payload.field === 'email' && !errors[action.payload.field]) {
      const rule: IValidateRule = {
        regex: {
          value: new RegExp(`(${autoregistration.allowedDomains.join(')|(')})`, 'g'),
          errorMessageKey: 'pryaniky.validate.email.allowedDomains',
          variableKey: autoregistration.allowedDomains.join(', ')
        }
      }
      errors = validate({[action.payload.field]: fields[action.payload.field]}, {[action.payload.field]: rule });
    }
    yield put(registrationChangeErrors(errors as any));
    return 0;
  } catch (error) {
    console.error('registration fields validate error:', error);
  }
}

function* registrationSubmit(action: IRegistrationSubmitAction) {
  try {
    const fields: IRegistrationFields = yield* select(getRegistrationFileds);
    const settings: ReturnType<typeof getRegistrationAutoregistration> = yield* select(getRegistrationAutoregistration);
    const { rc } = queryStringToObj(window.location.search);
    const requiredFields: (keyof IRegistrationFields)[] = yield* select(getRegistrationReuiredFields);
    const fieldsToValidate = {
      ...fields,
      ...requiredFields.filter(e => !Object.typedKeys(fields).includes(e)).reduce((a, c) => ({ ...a, [c]: '' }), {} as typeof fields)
    }
    let errors = validate(fieldsToValidate, Object.keys(registrationsValidateFileldsRules).reduce((acc, c) => requiredFields.includes(c as keyof IRegistrationFields) ? { ...acc, [c]: registrationsValidateFileldsRules[c] }  : acc, {} as any) );
    if(Object.keys(errors).filter(key => errors[key as keyof IRegistrationFieldsErrors]).length) {
      yield put(registrationChangeErrors(errors as any));
      return 0;
    }
    const data: Parameters<typeof API.system.sagas.registration>['0'] = {
      code: fields.code,
      comment: fields.comment,
      division: fields.division,
      email: fields.email,
      firstName: fields.firstName,
      imgId: fields.imgId,
      lastName: fields.lastName,
      middleName: fields.middleName,
      phone: fields.phone,
      position: fields.position,
      additionalFields: settings.fields,
      sitename: fields.sitename
    }
    const response = yield* call(API.system.sagas.registration, data, rc?.toString()) as any;
    if(checkResponseStatus(response)) {
      if(response.data) console.log('Registration is finish');
      toast.success(Translate.t({ i18nKey: 'pryaniky.registration.success' }));
      yield put(registrationChangeReducerField({ field: 'sended', value: true }));
    } else {
      if(response.error_code === 2006) toast.error(Translate.t({ i18nKey: `pryaniky.registration.error.error_code.2006` }));
      if(response.error_code === 11006) toast.error(Translate.t({ i18nKey: 'pryaniky.registration.error.error_code.11006' }));
      if(response.error_code === 11008) toast.error(Translate.t({ i18nKey: 'pryaniky.registration.error.error_code.11008' }));
    }
  } catch (error) {
    console.error('registration submit error:', error);
  }
}

export const registrationSaga = function* loginSaga() {
  yield takeLeading(REGISTRATION_CHANGE_FIELD, validateFields);
  yield takeLeading(REGISTRATION_SUBMIT, registrationSubmit);
};

export default registrationSaga;