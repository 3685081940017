import { IModuledStoreType } from "../store";

/**
 * get login current state
 * @param { IModuledStoreType } state - current state
 */
export const getLogin = (state: IModuledStoreType) => state.login;

/**
 * get values from login state
 * @param { IModuledStoreType } state - current state
 */
export const getLoginValues = (state: IModuledStoreType) => getLogin(state).values;

/**
 * get values from login state
 * @param { IModuledStoreType } state - current state
 */
export const getLoginIntegrations = (state: IModuledStoreType) => getLogin(state).integrations;

/**
 * get errors from login state
 * @param { IModuledStoreType } state - current state
 */
export const getLoginErrors = (state: IModuledStoreType) => getLogin(state).errors;

/**
 * get success from login state
 * @param { IModuledStoreType } state - current state
 */
export const getLoginSuccess = (state: IModuledStoreType) => getLogin(state).success;

/**
 * get subnetworks from login state
 * @param { IModuledStoreType } state - current state
 */
export const getLoginSubnetworks = (state: IModuledStoreType) => getLogin(state).subnetworks;

/**
 * get companyNameIsExist from login state
 * @param { IModuledStoreType } state - current state
 */
export const getLoginIsExistCompanyName = (state: IModuledStoreType) => getLogin(state).companyNameIsExist;

/**
 * получает фронтовой список провайдеров авторизации для компании
 * @param { IModuledStoreType } state - current state
 */
export const getLoginProviders = (state: IModuledStoreType) => getLogin(state).providers;

/**
 * получает объект выбранного провайдера для авторизации
 * @param { IModuledStoreType } state - current state
 */
export const getSelectedLoginProvider = (state: IModuledStoreType) => getLoginProviders(state).filter(e => e.selected)[0];

/**
 * получает текущее состояние объекта сервисных состояний
 * @param { IModuledStoreType } state 
 * @returns ILoginService
 */
export const getLoginService = (state: IModuledStoreType) => getLogin(state).service;

/**
 * get current value of login reducer field by name
 * @param field 
 * @returns 
 */
export const getLoginField = <T extends keyof IModuledStoreType['login'], S extends IModuledStoreType = IModuledStoreType> (field: T) => (s: S) => getLogin(s)[field];