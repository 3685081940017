import React from 'react';
import { mapLanguageSelectorStateToProps, mapLanguageSelectorActionsToProps, ILanguageSelectorProps, cnLanguageSelector } from './LanguageSelector.index';
import { Dropdown } from 'uielements/src';
import { title } from 'process';
import { connect } from 'react-redux';
import { Translate } from "localization";
import './LanguageSelector.scss';

/**
 * 
  title: React.ReactNode;
  type?: string;
  buttonProps?: IButtonProps;
  isActive?: boolean;
 */

const LanguageSelectorPresenter: React.FC<ILanguageSelectorProps> = ({ language, languages, i18n_set_language }) => {
  if(languages.length <= 1) return null;
  return (
    <Dropdown 
      withCaret
      className={cnLanguageSelector()}
      text={<Translate i18nKey={`lng_${language}`} ns={'default'} />}
      options={languages.map(lng => ({ 
        title: <Translate i18nKey={`lng_${lng}`} ns={'default'} />,
        buttonProps: { onClick: () => i18n_set_language(lng) } 
      }))}
      />
  )
}

export const LanguageSelector = connect(
  mapLanguageSelectorStateToProps,
  mapLanguageSelectorActionsToProps
)(
  LanguageSelectorPresenter
);