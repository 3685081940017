import { put, takeEvery, takeLeading, delay, takeLatest } from 'redux-saga/effects';
import { call, select } from 'utils/src/saga.effects';
import { push } from 'connected-react-router';
import { IFeedbackAction, IFeedbackGetSettingsAction } from './actions.index';
import { getFeedback, getFeedbackValues } from './selectors';
import { FEEDBACK, feedback, feedback_error_clear, feedback_error, feedback_values_clear, feedback_set_request, FEEDBACK_GET_ADDITIONAL_DATA, FEEDBACK_GET_SETTINGS, feedback_put_settings } from './actions';
import { getRouter } from 'connected-react-router';
import { IFeedbackValues, IFeedbackReducer } from './interfaces';
import { API, checkResponseStatus } from 'utils/src/utils';
import { i18n } from "localization";
import { toast } from 'react-toastify';
import { getSiteUrl } from '../base/selectors';
import { getUserById } from '../users/selectors';
import { IFeedbackSettings } from 'utils/src/requests/models/api.system';
import { IResponseWithData } from 'utils/src/requests/models/api.base';

function* feedbackRequest(action: IFeedbackAction) {
  try {
    yield put(feedback_error_clear());
    yield put(feedback_set_request(true));
    const data: IFeedbackValues = yield* select(getFeedbackValues);
    const response = yield* call(API.system.sagas.feedback, { isLike: action.payload.isLike, senderName: data.user, senderContacts: data.contacts, comment: data.comment, userId: action.payload.user });
    yield put(feedback_set_request(false));
    if(checkResponseStatus(response)) {
      toast.success(i18n.t('pryaniky.feedback.success'));
      yield put(feedback_values_clear());
      return 0;
    } else {
      toast.error(i18n.t('pryaniky.feedback.error'));
    }

    // const data = yield* call(getGames, action.payload);
    // yield put(gamesSetGames({
    //   isFinished: action.payload.count > data.data.length,
    //   data
    // }));
  } catch (e) {
    console.error('send feedback error', e);
  }
}

// function* additionalData(action: IFeedbackGetAdditionalDataAction ) {
//   try {
//     // const data = yield* call(API.system.sagas.feedbackAdditionalData);
//     const data = yield* call((url: string) => fetch(url).then(r => r.json()), '/additionalProps/feedback.json');
//     const companyName = yield* select(getSiteUrl);
//     return yield put(feedback_set_additional_data(data[companyName]));
//   } catch (error) {
//     console.error('get additional feedback data error', error);
//   }
// }

function* getSettings(action: IFeedbackGetSettingsAction) {
  yield put(feedback_set_request(true));
  try {
    const dt: IResponseWithData<IFeedbackSettings> = yield* call(API.system.sagas.getFeedbackSettings);
    if(checkResponseStatus(dt)) {
      yield put(feedback_put_settings(dt.data));
    }
    return ;
  } catch (error) {
    console.log('get feedback settings error', error);
  } finally {
    yield put(feedback_set_request(false));
  }
}

export const feedbackSaga = function* login() {
  yield takeLeading(FEEDBACK, feedbackRequest);
  // yield takeEvery(FEEDBACK_GET_ADDITIONAL_DATA, additionalData);
  yield takeEvery(FEEDBACK_GET_SETTINGS, getSettings)
};

export default feedbackSaga;