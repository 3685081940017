import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { IModuledStoreType } from 'redux/store';
import { bindActionCreators, Dispatch, AnyAction } from 'redux';
import actions from 'redux/base/actionsTypes';

export interface IBackgroundProps extends IClassNameProps, ReturnType<typeof mapStateToProps>, ImapBackgroundActionsToProps {
  tag?: 'div';
}

export interface IBackgroundState {
  text?: string;
  back: any;
  backColor: string;
}

export const mapStateToProps = (state: IModuledStoreType) => ({
  auth: state.store.auth,
  backgroundUrl: state.store.appDesign?.backgroundUrl !== '' ? state.store.appDesign?.backgroundUrl : undefined,
});

export const mapBackgroundActionsToProps = (d: Dispatch<AnyAction>) => bindActionCreators({
  changeVm: (propPath: string, value: any) => ({
    type: actions.CHANGE_STATE,
    payload: {
      propPath,
      value
    } 
  })
}, d)

export type ImapBackgroundActionsToProps = ReturnType<typeof mapBackgroundActionsToProps>

export const cnBackground = cn('Background');