const urls = {
  login: '/login',
  registration: '/registration',
  forgot: '/forgot',
  resetPassowrd: ['/resetpassword/:password/:uId', '/firstlogin/:password/:uId'],
  feedback: '/feedback/:uId',
  logout: '/logout'
}

export {
  urls
}