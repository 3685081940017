import { cn } from '@bem-react/classname';
import { IModuledStoreType } from 'redux/store';

export const cnBasePage = cn('BasePage');

export interface IBasePageProps extends ImapBasePageStateToProps {

};

export const mapBasePageStateToProps = (state: IModuledStoreType) => ({
});

export type ImapBasePageStateToProps = ReturnType<typeof mapBasePageStateToProps>;