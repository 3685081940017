import React from 'react';
import { ILayoutProps, ILayoutState, cnLayout, mapLayoutStateToProps, mapLayoutActionsToProps } from './Layout.index';
import { BasePage } from '../BasePage/BasePage';
import { routes } from '../../routes/routes';
import { Route, withRouter, Redirect, Switch } from 'react-router';
import './Layout.scss';
import { connect } from 'react-redux';
import * as utils from 'utils/src/utils';
import { denormalize, normalize, schema } from 'normalizr';
import { integrationModes, paramToDeleteAllCookie } from 'utils/src/constants.prn';
import { CacheHelper } from 'utils/src/CacheHelper';
import { urls } from 'routes/urls';
import { Stub } from 'uielements/src';
import { LanguageSelector } from 'blocks/LanguageSelector/LanguageSelector';
import { setCustomDesign } from './customCss';
// pageComponents.pathRenderer(route.c.c, route.c.prp)

const loadingRedirectIgnore = [ urls.login, urls.logout, urls.forgot, ...urls.resetPassowrd, urls.feedback, urls.registration ].map(el => el.substr(1).split('/')[0]);
const removeFromSearchToRedirect = [ paramToDeleteAllCookie ];

let timer: any;
class LayoutPresenter extends React.Component<ILayoutProps, ILayoutState> {
  public utils = utils;
  private notReSetRedirectCookie: boolean = false;
  private redirect: string;
  private redirectAfterLogin: string;
  private platform: string;
  private mobile: boolean;
  private isInFrame =
    window.location.pathname.includes('/wv/') ||
    window.location.pathname.includes('/widgets/') ||
    window.location.pathname.includes('/Widgets/');
  // private i18n: any;

  constructor(props: ILayoutProps) {
    super(props);

    props.i18n_check_language_namespace();
    // document.title = i18n.t('project name');
    if (props.location.search.substr(1).split(/[&=]/).includes(paramToDeleteAllCookie)) utils.deleteAllCookies(); // history.push('/login');

    this.checkAuth();
    const pathname = props.location.pathname;
    this.checkRedirect(pathname);

    const reloadRedir = utils.getCookie('reload-redirect');
    if(reloadRedir && loadingRedirectIgnore.reduce((a, c) => {
      if(a) return a;
      return reloadRedir.slice(1).startsWith(c);
    }, false)) utils.setCookie({ name: 'reload-redirect', value: '', props: { expires: new Date(0)} });

    if (pathname !== '/' && !loadingRedirectIgnore.includes(pathname.substring(1).split('/')[0])) {
      this.redirectAfterLogin = pathname + this.searchToRedirect + props.location.hash;
    } else {
      this.redirectAfterLogin = '/'//'/dash';
    }

    const userAgent = navigator.userAgent;
    this.mobile = /(andoriod)|(iphone)/i.test(userAgent);
    if (userAgent.match(/macintosh/i)) {
      this.platform = 'Mac';
    } else if (userAgent.match(/windows/i)) {
      this.platform = 'Windows';
    } else if (userAgent.match(/linux/i)) {
      this.platform = 'Linux';
    }
    const integatedMode = utils.getCookie('integrationMode');
    if (integatedMode === integrationModes.sharepoint && (window.self !== window.top || window.location.pathname.indexOf('/integrate') !== -1)) {
      const scriptv2 = document.createElement('script');
      // scriptv2.src = 'http://localhost:9999/pryaniky.rpc.js'
      // scriptv2.setAttribute('src', (window.location.hostname.indexOf('localhost') === -1 && window.location.pathname.indexOf('/NewDesign') !== -1 ? '/NewDesign' : '') + '/integration.js');
      // scriptv2.setAttribute('src', basename + '/integration.js');
      document.head.append(scriptv2);
    }
    if (utils.isAuthInQueryStringv2()) {
      const queryparams = utils.getQueryStringAuthv2();
      // utils.setCookie({ name: 'companyName', value: queryparams.companyName });
      utils.setCookie({ name: 'DEV_companyName', value: queryparams.companyName });
      utils.setCookie({ name: 'authUid', value: queryparams.authUid });
      utils.setCookie({ name: 'authAt', value: queryparams.authAt });
      utils.setCookie({ name: '.PRYANIKYAC', value: queryparams['.PRYANIKYAC'] });
      // document.cookie = `companyName=${queryparams.companyName}; path=/; samesite=None; secure`;
      // document.cookie = `DEV_companyName=${queryparams.companyName}; path=/; samesite=None; secure`;
      // document.cookie = `authUid=${queryparams.authUid}; path=/; samesite=None; secure`;
      // document.cookie = `authAt=${queryparams.authAt}; path=/; samesite=None; secure`;
      // document.cookie = `.PRYANIKYAC=${queryparams['.PRYANIKYAC']}; path=/; samesite=None; secure`;
      if (window.location.hostname.indexOf('localhost') !== -1) {
        alert('я сделяль! авторизация установлена');
      } else {
        window.location.assign(this.redirectAfterLogin);
      }
    }
    // this.props.updateContext('common', utils.searchObject(this.props.location.search))
    // this.props.setContext({
    //   paths: ['common'],
    //   innerPath: '',
    //   value: utils.searchObject(this.props.location.search),
    // })
  }

  /**
   * componentDidMount
   */
  public componentDidMount() {
    this.login();
  }

  private get searchToRedirect() {
    let { search } = this.props.location;
    removeFromSearchToRedirect.map(rem => {
      if (search.includes(`?${rem}=`) || search.includes(`&${rem}=`))
        return search.substring(search.indexOf(rem), search.indexOf('&', search.indexOf(rem)) > 0 ? search.indexOf('&', search.indexOf(rem)) + 1 : search.length); // get search rem value, include '&' char if exist
      return '';
    }).forEach(remVal => {
      search = search.replace(remVal, '').replace(/&$/, '');
    })
    return search === '?' ? '' : search;
  }

  /**
   * render
   */
  public render() {
    const { loading, location } = this.props;
    if(loading) return (
      <div className={cnLayout({
        authorization: true
      })}>
        <BasePage>
          <Stub />
        </BasePage>
      </div>
    )
    
    return (
      <div className={cnLayout({
          authorization: true
        })}>
        <div style={{ zIndex: 99999999999999999, color: 'red' }}>
          {/* <Translate i18nKey={'about_colleague'} namespace={'default'} language={'ru'} rerender={false} /> */}
          {/* <Translate i18nKey={'about_colleague'} /> */}
        </div>
        <BasePage>
          <LanguageSelector />
          <Switch location={location}>
            {
              routes.map((route, i) => 
                <Route 
                key={i}
                path={route.path}
                {...route.prp}
                render={() => <route.c.c {...route.c.prp} />} />
              )
            }
            {/* <Redirect to={urls.login} /> */}
          </Switch>
        </BasePage>
      </div>
    )
  }

  private get urlSearchObj() {
    return this.props.location.search.substring(1).split('&').reduce((acc, cur) => {
      const cur_data = cur.split('=');
      acc[cur_data[0]] = cur_data[1];
      return acc;
    }, {} as any)
  }

  private login = () => {
    if (utils.isAuthInQueryStringv2()) {
      const queryparams = utils.getQueryStringAuthv2();
      // utils.setCookie({ name: 'companyName', value: queryparams.companyName });
      utils.setCookie({ name: 'DEV_companyName', value: queryparams.companyName });
      utils.setCookie({ name: 'authUid', value: queryparams.authUid });
      utils.setCookie({ name: 'authAt', value: queryparams.authAt });
      utils.setCookie({ name: '.PRYANIKYAC', value: queryparams['.PRYANIKYAC'] });
      // document.cookie = `companyName=${queryparams.companyName}; path=/; samesite=None; secure`;
      // document.cookie = `DEV_companyName=${queryparams.companyName}; path=/; samesite=None; secure`;
      // document.cookie = `authUid=${queryparams.authUid}; path=/; samesite=None; secure`;
      // document.cookie = `authAt=${queryparams.authAt}; path=/; samesite=None; secure`;
      // document.cookie = `.PRYANIKYAC=${queryparams['.PRYANIKYAC']}; path=/; samesite=None; secure`;
      if (window.location.hostname.indexOf('localhost') !== -1) {
        alert('я сделяль! авторизация установлена');
      } else {
        window.location.assign(this.redirectAfterLogin);
      }
    }
    // const { authAt, authUid, companyName } = utils.getAuthCookies();
    const { authAt, authUid } = utils.getAuthCookies();
    const integatedMode = utils.getCookie('integrationMode');
    if (authAt && authUid && window.self !== window.top && integatedMode === integrationModes.sharepoint) {
      // timer = setInterval(() => {
      //   if (window.PryanikyRpc) {
      //     clearInterval(timer);
      //     const fid = uuid();
      //     const initFunc = (rpcInit: boolean) => {
      //       if (!rpcInit) {
      //         this.setBaseData();
      //       } else {
      //         const fid2 = uuid();
      //         window.PryanikyRpc.init().functions[fid2] = this.setBaseStore;
      //         window.PryanikyRpc.message.post({ name: MESSAGES_TYPES.get, type: 'base_store', to_frame: this.urlSearchObj.frame_id, function_id: fid2 });
      //       }
      //     };
      //     window.PryanikyRpc.init().functions[fid] = initFunc;
      //     window.PryanikyRpc.message.post({ name: MESSAGES_TYPES.get, type: 'init', to_frame: this.urlSearchObj.frame_id, function_id: fid });
      //   }
      // }, 100);
    }
    else {
      if (this.redirectAfterLogin && this.redirectAfterLogin !== '/' && !utils.getCookie('reload-redirect')) utils.setCookie({ name: 'reload-redirect', value: this.redirectAfterLogin });
      this.props.changeVm('loading', false);
    }
  };

  private setBaseStore = (data: any) => {
    this.props.changeVm('', data);
  }

  private setBaseDataWork = (d: any, callback?: () => void) => {
    // const authUser = {
    //   baseData: d.data.baseData,
    //   extData: d.data.extData,
    //   profileData: d.data.profileData,
    // };
    const value = {
      // authUser,1
      appSettings: d.data.appSettings,
      appDesign: d.data.appDesign,
      chatSettings: d.data.chat,
      disabledThanks: false,
      //  this.utils.getCookie('companyName').toLowerCase() === 'dns',
      auth: true,
      loading: false,
    };
    // this.setCustomDesign(d.data.appDesign);
    // this.redirectAfterLogin = this.redirectAfterLogin || '/dash';
    this.props.setAuthUser(d.data.baseData.id);

    const field = new schema.Entity('fields', {}, { idAttribute: 'fieldId' });
    const normalizedUserInfoFields = normalize(d.data.profileData.userInfoFields, [field]);
    d.data.profileData.userInfoFields = normalizedUserInfoFields;
    const normalizedUserHeadFields = normalize(d.data.profileData.userHeadFields, [field]);
    d.data.profileData.userHeadFields = normalizedUserHeadFields;

    this.props.loadCompanyPostTypes()

    this.props.changeUser(d.data.baseData.id, {
      baseData: d.data.baseData,
      extData: d.data.extData,
      profileData: d.data.profileData,
    });
    this.setBaseStore(value);
    // this.props.changeVm('', value);
    // ChatService.init();
    const integatedMode = utils.getCookie('integrationMode');
    if (window.self !== window.top && integatedMode === integrationModes.sharepoint) {
      // timer = setInterval(() => {
      //   if (window.PryanikyRpc) {
      //     clearInterval(timer);
      //     window.PryanikyRpc.message.post({ name: MESSAGES_TYPES.initBaseStore, data: value }, 'top');
      //     window.PryanikyRpc.message.post({ name: 'init' }, 'top');
      //   }
      // }, 100);
    }
    if (callback) callback();
  }
  private setBaseData = (callback?: () => void) => {
    CacheHelper.get('initData', `${this.utils.getCookie('authUid')}?fullData=true?appSettings=true`)
      .then((value) => value && this.setBaseDataWork(utils.clone(value), callback))
      .catch(e => console.warn('CacheHelper, initData:', e))

    utils.API.users.byId(this.utils.getCookie('authUid'), { fullData: true, appSettings: true/*, include: 'lms' */ }).r.then((d: any) => {
      if (utils.checkResponseStatus(d)) {

        CacheHelper.set('initData', `${this.utils.getCookie('authUid')}?fullData=true?appSettings=true`, utils.clone(d))
          .then((value) => console.log(value))
          .catch(e => console.warn('CacheHelper, initData:', e))

        this.setBaseDataWork(d, callback)
      }
    });
  };

  private redirectTo = (afterLogin?: boolean) => {
    const url = afterLogin ? this.redirectAfterLogin : this.redirect;
    afterLogin ? (this.redirectAfterLogin = '') : (this.redirect = '');
    return url as string;
  };

  private checkRedirect = (pathname: string) => {
    const hasAuthCookies = Object.values(utils.getAuthCookies()).filter(Boolean).length === 2;
    if (hasAuthCookies) {
      if (pathname === '/login' || pathname === '/') this.redirect = '/'//'/dash';
    } else if (!loadingRedirectIgnore.includes(pathname.substring(1).split('/')[0])) {
      this.redirect = '/login';
    }
    if (hasAuthCookies && utils.getCookie('reload-redirect') && utils.getCookie('reload-redirect').toString() !== 'undefined') {
      this.redirect = utils.getCookie('reload-redirect');
      // utils.setCookie({ name: 'reload-redirect', value: '', props: { expires: new Date(0) } });
      // document.cookie = `reload-redirect=; path=/; expires=${new Date(0)}; samesite=None; secure`;
      this.notReSetRedirectCookie = true;
    }
  };

  private checkAuth = () => {
    const authMatch = window.location.search.match(/auth=([^&]+)&*/);
    if (authMatch) {
      const auth = authMatch[1].split(';').map(decodeURIComponent);
      utils.setCookie({ name: 'authAt', value: auth[0] });
      utils.setCookie({ name: 'authUid', value: auth[1] });
      // utils.setCookie({ name: 'companyName', value: auth[2] });
      // document.cookie = 'authAt=' + auth[0] + '; path=/; samesite=None; secure';
      // document.cookie = 'authUid=' + auth[1] + '; path=/; samesite=None; secure';
      // document.cookie = 'companyName=' + auth[2] + '; path=/; samesite=None; secure';
    }
  };
}

export const Layout = withRouter(
  connect(
    mapLayoutStateToProps,
    mapLayoutActionsToProps
  )(LayoutPresenter)
);