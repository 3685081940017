import { constants } from 'utils/src/constants.prn';
import { ACTION } from '../actionsTypes';

let actions = {
  ADD_USER: '',
  SET_STATUS: '',
  REMOVE_USER: '',
  CHANGE_USER: '',
  SET_SUBSCRIBE: '',
  SET_VIRT_USERS: '',
}

export default actions = Object.keys(actions).reduce((acc, cur) => ({ ...acc, [cur]: ACTION(cur, constants.REDUCERS.USERS)}), {} as typeof actions);