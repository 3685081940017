import React from 'react';
import { connect } from 'react-redux';
import { IRegistrationFields } from 'redux/registration/interfaces';
import { cnRegisterForm, IRegisterFormProps, mapRegisterFormActionToProps, mapRegisterFormStateToProps } from './RegisterForm.index';
import './RegisterForm.scss';
import { phoneMaskFunc } from 'utils/src/utils';
import { Translate } from "localization";
import { Input, Button, HelpTooltip, Icon, Select } from 'uielements/src';
import MDRender from 'uielements/src/CommonmarkRender';
// import { useMemo } from 'react';
import BaseRequests from 'utils/src/requests/requests.base';
import FieldRender from './FieldsRender/FieldRender/FieldRender';
import DateFnsUtils from '@date-io/date-fns';
import ruLocale from "date-fns/locale/ru";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";

const phoneRegEx = new RegExp(/^\+\d*\(?\d*\)?\d*$/, 'g');

const nameLastNameExcludedSymbolsRegEx = new RegExp(/[,.\\|/\(\)\s#@\+\*\&%=]+/, 'g');

const regExpFields: (keyof IRegistrationFields)[] = ['lastName', 'firstName', 'middleName'];

const RegisterFormPresenter: React.FC<IRegisterFormProps> = ({
  fields,
  errors,
  reqired,
  settings,
  sended,
  registrationChangeField,
  registrationSubmit,
  registrationChangeReducerField
}) => {

  // React.useEffect(() => {
  //   login_get_company_settings();
  // }, []);

  const putValue = (field: keyof IRegistrationFields, value: string) => {
    if(regExpFields.includes(field)) {
      value.match(nameLastNameExcludedSymbolsRegEx)?.forEach(symbol => value = value.replace(symbol, ''));
    }
    registrationChangeField({ field, value });
  } 

  const changeField = (field: keyof IRegistrationFields) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let value = event.currentTarget.value;
    putValue(field, value);
  }

  const changeAdditionalFields = (field: keyof IRegistrationFields, value: any, valueType: string) => {
    registrationChangeField({ field, value, valueType });
  }

  const phoneHandleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value } = e.currentTarget;
    value = value.replace(/[-\s\(\)]/g, '');
    let phone = '';
    let mask2: any[] = [];
    value.split('').forEach(s => {
      phone += s;
      mask2 = phoneMaskFunc(phone);
      phone = mask2.map((e, i) => e instanceof RegExp ? phone[i] || (i === phone.length && phone[i - 1]) : e).join('');
    });
    if(!value) {
      mask2 = phoneMaskFunc(phone);
      phone = mask2.map((e, i) => e instanceof RegExp ? phone[i] || (i === phone.length && phone[i - 1]) : e).join('');
    }
    if (!phone.match(phoneRegEx)) return;
    registrationChangeField({ field: 'phone', value: phone });
    e.type === 'focus' && !value && (e.currentTarget.selectionStart = phone.length);
  }
  
  const onPhoneBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    if(e.currentTarget.value === '+') registrationChangeField({ field: 'phone', value: '' });
  }

  const submitForm = (event: any) => {
    event.preventDefault();
    registrationSubmit();
  }

  const prepareNewForm = () => {
    registrationChangeReducerField({ field: 'fields', value: { imgId: '', code: '', comment: '', division: '', email: '', firstName: '', lastName: '', middleName: '', phone: '', position: '', sitename: '' } });
    registrationChangeReducerField({ field: 'sended', value: false });
  }

  return (
    <div className={cnRegisterForm()}>
      {
        sended &&
        <div className={cnRegisterForm('Success')}>
          <div>
            <Translate i18nKey={'pryaniky.registration.success'} />
          </div>
          <Button className={cnRegisterForm('NewRequest')} onClick={prepareNewForm} id={'registrationSendNewRequestButton'} main>
            <Translate i18nKey={'pryaniky.registration.oneMore'} />
          </Button>
        </div>
      }
      {
        !sended &&
        <>
          <div className={cnRegisterForm('Text')}>
            <b>
              <Translate i18nKey={'pryaniky.registration.title'} />
            </b>
            <div>
            <Translate i18nKey={'source:pryaniky.registration.info'}>
              <MDRender source={''} />
            </Translate>
            </div>
          </div>
          <form onSubmit={submitForm}>
            <Translate
              i18nKey={`placeholder:pryaniky.registration.firstName${errors?.firstName ? `;errorMessage:${(errors?.firstName as any)?.errorMessageKey}` : ''}`}
              values={(errors?.firstName as any)?.variables}
              variablesI18nKeys={{variable: (errors?.firstName as any)?.variableKey}}
              >
              <Input
                required={reqired.includes('firstName')}
                id={'registrationFirstName'}
                invalid={!!errors?.firstName}
                // errorMessage={errors?.firstName && Translate.t({ i18nKey: (errors?.firstName as any)?.errorMessageKey, values: (errors?.firstName as any)?.variables, variablesI18nKeys: { variable: (errors?.firstName as any)?.variableKey } })}
                icon="text"
                onChange={changeField('firstName')}
                name="firstName"
                value={fields.firstName}
                // placeholder={Translate.t({ i18nKey: 'pryaniky.registration.firstName' })}
              >
                <Translate i18nKey={'help:pryaniky.registration.help.firstName'} require>
                  <HelpTooltip><Icon icon="question-circle" /></HelpTooltip>
                </Translate>
                {/* {
                  infos.firstName &&
                  <HelpTooltip help={infos.firstName}><Icon icon="question-circle" /></HelpTooltip>
                } */}
              </Input>  
            </Translate>
            <Translate
              i18nKey={`placeholder:pryaniky.registration.lastName${errors?.lastName ? `;errorMessage:${(errors?.lastName as any)?.errorMessageKey}` : ''}`}
              values={(errors?.lastName as any)?.variables}
              variablesI18nKeys={{ variable: (errors?.lastName as any)?.variableKey } }
              >
              <Input
                required={reqired.includes('lastName')}
                id={'registrationLastName'}
                invalid={!!errors?.lastName}
                // errorMessage={errors?.lastName && Translate.t({ i18nKey: (errors?.lastName as any)?.errorMessageKey, values: (errors?.lastName as any)?.variables, variablesI18nKeys: { variable: (errors?.lastName as any)?.variableKey } })}
                icon="text"
                onChange={changeField('lastName')}
                name="lastName"
                value={fields.lastName}
                // placeholder={Translate.t({ i18nKey: 'pryaniky.registration.lastName' })}
                >
                <Translate i18nKey={'help:pryaniky.registration.help.lastName'} require>
                  <HelpTooltip><Icon icon="question-circle" /></HelpTooltip>
                </Translate>
                {/* {
                  infos.lastName &&
                  <HelpTooltip help={infos.lastName}><Icon icon="question-circle" /></HelpTooltip>
                } */}
              </Input>
            </Translate>
            <Translate
              i18nKey={`placeholder:pryaniky.registration.position`}
              >
              <Input
                id={'registrationPosition'}
                icon="text"
                onChange={changeField('position')}
                name="position"
                value={fields.position}
                // placeholder={Translate.t({ i18nKey: 'pryaniky.registration.position' })}
                >
                <Translate i18nKey={'help:pryaniky.registration.help.position'} require>
                  <HelpTooltip><Icon icon="question-circle" /></HelpTooltip>
                </Translate>
                {/* {
                  infos.position &&
                  <HelpTooltip help={infos.position}><Icon icon="question-circle" /></HelpTooltip>
                } */}
              </Input>
            </Translate>
            <Translate
              // i18nKey={`placeholder:pryaniky.registration.division`}
              i18nKey={`placeholder:pryaniky.registration.division${errors?.division ? `;errorMessage:${(errors?.division as any)?.errorMessageKey}` : ''}`}
              values={(errors?.division as any)?.variables}
              variablesI18nKeys={{ variable: (errors?.division as any)?.variableKey } }
              >
              <Input
                id={'registrationDivision'}
                required={reqired.includes('division')}
                icon="text"
                onChange={changeField('division')}
                name="division"
                value={fields.division}
                invalid={!!errors?.division}
                // placeholder={Translate.t({ i18nKey: 'pryaniky.registration.division' })}
                >
                <Translate i18nKey={'help:pryaniky.registration.help.division'} require>
                  <HelpTooltip><Icon icon="question-circle" /></HelpTooltip>
                </Translate>
                {/* {
                  infos.division &&
                  <HelpTooltip help={infos.division}><Icon icon="question-circle" /></HelpTooltip>
                } */}
              </Input>
            </Translate>
            {
              // если включена регистрация с разрешенными доменами и эти домены определены, то показываем азполняемый селектор, иначе обычный инпут
              (
                settings.enableWithTrustedDomains &&
                settings.allowedDomains.length
              ) ?
              <Select
                noDefault
                onChange={(select) => {
                  select && putValue('email', select.value);
                }}
                options={settings.allowedDomains.map(e => {
                  const value = fields.email;
                  const name = value.includes('@') ? value.substring(0, value.indexOf('@')) : value;
                  return {
                    title: name + '@' + e,
                    value: name + '@' + e
                  }
                })}
                dropDownInfo={
                  <Translate
                    i18nKey={'pryaniky.registration.email.ddInfo'}
                    variablesI18nKeys={settings.enable ? {info: 'pryaniky.registration.email.ddInfoAll'} : undefined}
                    />
                }
                renderInput={
                  (open, setOpen, children, selected ) => {
                    return <Translate
                      i18nKey={`placeholder:pryaniky.registration.email${errors?.email ? `;errorMessage:${(errors?.email as any)?.errorMessageKey}` : ''}`}
                      values={(errors?.email as any)?.variables}
                      variablesI18nKeys={{variable: (errors?.email as any)?.variableKey}}
                      >
                        <Input
                        required={reqired.includes('email')}
                        invalid={reqired.includes('email') && !!errors?.email}
                        id={'registrationEmail'}
                        icon="text"
                        onFocus={() => setOpen(true)}
                        value={fields.email}
                        onChange={changeField('email')}
                        name="email"
                        >
                        {children}
                        <Translate i18nKey={'help:pryaniky.registration.help.email'} require>
                          <HelpTooltip><Icon icon="question-circle" /></HelpTooltip>
                        </Translate>
                      </Input>
                    </Translate>
                  }
                }
              >
              </Select>
              :
              <Translate
                i18nKey={`placeholder:pryaniky.registration.email${errors?.email ? `;errorMessage:${(errors?.email as any)?.errorMessageKey}` : ''}`}
                values={(errors?.email as any)?.variables}
                variablesI18nKeys={{variable: (errors?.email as any)?.variableKey}}
              >
                <Input
                required={reqired.includes('email')}
                invalid={reqired.includes('email') && !!errors?.email}
                id={'registrationEmail'}
                icon="text"
                value={fields.email}
                onChange={changeField('email')}
                name="email"
                >
                  <Translate i18nKey={'help:pryaniky.registration.help.email'} require>
                    <HelpTooltip><Icon icon="question-circle" /></HelpTooltip>
                  </Translate>
                </Input>
              </Translate>

            }

            <Translate
              i18nKey={`placeholder:pryaniky.registration.phone${errors?.phone ? `;errorMessage:${(errors?.phone as any)?.errorMessageKey}` : ''}`}
              values={(errors?.phone as any)?.variables}
              variablesI18nKeys={{variable: (errors?.phone as any)?.variableKey}}
              >
              <Input
                required={reqired.includes('phone')}
                invalid={reqired.includes('phone') && !!errors?.phone}
                onBlur={onPhoneBlur}
                // errorMessage={errors?.phone && Translate.t({ i18nKey: (errors?.phone as any)?.errorMessageKey, values: (errors?.phone as any)?.variables, variablesI18nKeys: { variable: (errors?.phone as any)?.variableKey } })}
                id={'registrationPhone'}
                icon="text"
                onChange={phoneHandleChange}
                name="phone"
                value={fields.phone}
                // placeholder={Translate.t({ i18nKey: 'pryaniky.registration.phone' })}
                >
                <Translate i18nKey={'help:pryaniky.registration.help.phone'} require>
                  <HelpTooltip><Icon icon="question-circle" /></HelpTooltip>
                </Translate>
                {/* {
                  infos.phone &&
                  <HelpTooltip help={infos.phone}><Icon icon="question-circle" /></HelpTooltip>
                } */}
              </Input>
            </Translate>
            {
              settings.useCode && 
              <Translate
                i18nKey={`placeholder:pryaniky.registration.code${errors?.code ? `;errorMessage:${(errors?.code as any)?.errorMessageKey}` : ''}`}
                values={(errors?.code as any)?.variables}
                variablesI18nKeys={{variable: (errors?.code as any)?.variableKey}}
                >
                <Input
                  required={reqired.includes('code')}
                  invalid={!!errors?.code}
                  // errorMessage={errors?.code && Translate.t({ i18nKey: (errors?.code as any)?.errorMessageKey, values: (errors?.code as any)?.variables, variablesI18nKeys: { variable: (errors?.code as any)?.variableKey } })}
                  id={'registrationCode'}
                  icon="text"
                  onChange={changeField('code')}
                  name="code"
                  value={fields.code}
                  // placeholder={Translate.t({ i18nKey: 'pryaniky.registration.code' })}
                  >
                  <Translate i18nKey={'help:pryaniky.registration.help.code'} require>
                    <HelpTooltip><Icon icon="question-circle" /></HelpTooltip>
                  </Translate>
                  {/* {
                    infos.code &&
                    <HelpTooltip help={infos.code}><Icon icon="question-circle" /></HelpTooltip>
                  } */}
                </Input>
              </Translate>
            }
            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
            {
              settings.fields.map(el => {
                return (
                  <Translate
                    i18nKey={`${errors && errors[el.fieldId] ? `;errorMessage:${(errors && errors[el.fieldId] as any)?.errorMessageKey}` : ''}`}
                    values={(errors && errors[el.fieldId] as any)?.variables}
                    variablesI18nKeys={{variable: (errors && errors[el.fieldId] as any)?.variableKey}}
                    >
                      <FieldRender
                        onChangeFields={(fieldId: string, valueType: string) => (value: { [s: string]: any }) => {
                          changeAdditionalFields(fieldId, value, valueType);
                        }}
                        field={el} />
                  </Translate>
                )
              })
            }
            </MuiPickersUtilsProvider>
            <div className={cnRegisterForm('Actions')}>
                <div className={cnRegisterForm('Agreement')}>
              {
                settings.enableAgreement &&
                <>
                  <Translate i18nKey="pryaniky.registration.agreement"/>
                  <Button theme={'unstyled_center'}  noAdminBasename type='link' target={'_blank'} href={BaseRequests.getUrl("/Data/v3/login/download/agreement.pdf", {}, undefined, undefined, true, true)}>
                    <Translate i18nKey={'pryaniky.registration.agreementItem'} />
                  </Button>
                </>
              }
              </div>
              <Button className={cnRegisterForm('Submit')} disabled={!!Object.keys(errors || {}).length} id={'registrationSendButton'} htmlType='submit' main>
                <Translate i18nKey={'pryaniky.registration.registrate'} />
              </Button>
            </div>
          </form>
        </>
      }
    </div>
    // <div className={cnRegisterForm()}>

    // </div>
  )
}

export const RegisterForm = connect(
  mapRegisterFormStateToProps,
  mapRegisterFormActionToProps
)(
  RegisterFormPresenter
);