import { IClassNameProps } from '@bem-react/core';
import { cn } from '@bem-react/classname';
import { Dispatch, Action, bindActionCreators } from 'redux';
import { IModuledStoreType } from '../../../../redux/store';
import {
  getLoginValues,
  getLoginErrors,
  getLogin,
  getLoginSuccess,
  getLoginProviders,
  getSelectedLoginProvider,
  getLoginService,
  getLoginField
} from '../../../../redux/login/selectors';
import {
  login,
  login_error,
  login_change_field,
  login_error_clear,
  login_get_company_settings,
  login_success,
  login_success_clear,
  login_select_provider,
  login_send_sms_sode,
  login_change_service,
  login_update_captcha
} from '../../../../redux/login/actions';
import { getRegistrationAutoregistration } from "../../../../redux/registration/selectors";
import { setBackgroundImg } from '../../../../redux/base/actions';

export const cnLoginForm = cn('LoginForm');

export interface ILoginFormProps extends ILoginFormOwnProps, IClassNameProps, ImapLoginFormStateToProps, ImapLoginFormActionToProps {

};

export interface ILoginFormOwnProps {

};

export const mapLoginFormStateToProps = (state: IModuledStoreType, props: ILoginFormOwnProps) => ({
  values: getLoginValues(state),
  errors: getLoginErrors(state),
  success: getLoginSuccess(state),
  companyNameIsExist: getLogin(state).companyNameIsExist,
  integrations: getLogin(state).integrations,
  request: getLogin(state).request,
  subnetworks: getLogin(state).subnetworks,
  providers: getLoginProviders(state),
  selectedProvider: getSelectedLoginProvider(state),
  service: getLoginService(state),
  captcha: getLoginField('captcha')(state),
  settingsLoading: getLoginField('settingsLoading')(state),
  disableUserAccess: state.appSettings?.disableUserAccess,
});

export type ImapLoginFormStateToProps = ReturnType<typeof mapLoginFormStateToProps>;

export const mapLoginFormActionToProps = (d: Dispatch<Action>) => bindActionCreators({
  login,
  login_error,
  login_change_field,
  setBackgroundImg,
  login_error_clear,
  login_get_company_settings,
  login_success,
  login_success_clear,
  login_select_provider,
  login_send_sms_sode,
  login_change_service,
  login_update_captcha
}, d);

export type ImapLoginFormActionToProps = ReturnType<typeof mapLoginFormActionToProps>;