import { bindActionCreators, Action, Dispatch } from "redux";
import { login_logout } from '../../redux/login/actions';

export interface ILogoutProps extends ILogoutOwnProps, IMapLogoutStateToProps, IMapLogoutActionsToProps {}

export interface ILogoutOwnProps {}

export const mapLogoutStateToProps = () => ({})

export type IMapLogoutStateToProps = ReturnType<typeof mapLogoutStateToProps>

export const mapLogoutActionsToProps = (dispatch: Dispatch<Action>, ownProps: ILogoutOwnProps) => bindActionCreators({
  login_logout
}, dispatch);

export type IMapLogoutActionsToProps = ReturnType<typeof mapLogoutActionsToProps>;