import React from 'react';
import { cnBasePage, IBasePageProps, mapBasePageStateToProps } from './BasePage.index';
import { Background } from '../Background/Background';
import { ToastContainer } from 'react-toastify';
import { connect } from 'react-redux';
import { DynamicModuleLoader } from 'redux-dynamic-modules';
import { getI18nModule } from 'utils/src/CommonRedux/i18n/reduxModule';


export const BasePagePresenter: React.FC = ({
  children
}) => {

  return (
    <>
      <ToastContainer />
      <Background />
      {children}
    </>
  )
}

export const BasePage = connect(
  mapBasePageStateToProps
)(BasePagePresenter)