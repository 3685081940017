import actions from '../users/actionsTypes';

const defaultUsers: any = {
    users: {},
    usersIds: [],
    alias: {},
    // virtUsers: {},
    virtUsers: []
    // groups: {}/* {files:[], selected: []}*/
};

export function usersReducer(state = defaultUsers, action: any) {
    switch (action.type) {
        case actions.ADD_USER:
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.uid]: action.payload.user
                },
                usersIds: [action.payload.uid, ...state.usersIds],
                alias: {
                    ...state.alias,
                    [action.payload.user.baseData.alias]: action.payload.user.baseData.id
                }
            };
        case actions.REMOVE_USER:
            return {
                ...state,
            };
        case actions.SET_VIRT_USERS:
            return {
                ...state,
                virtUsers: action.payload
            }
        case actions.SET_SUBSCRIBE:
            return (() => {
                return {
                    ...state,
                    users: {
                        ...state.users,
                        [action.payload.uid]: {
                            ...state.users[action.payload.uid],
                            extData: {
                                ...state.users[action.payload.uid].isSubscribedByMe,
                                isSubscribedByMe: action.payload.isSubscribedByMe
                            }
                        }
                    },
                }
            })()
        case actions.CHANGE_USER:
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.uid]: {
                        ...state.users[action.payload.uid],
                        ...action.payload.data
                    }
                },
            };
        case actions.SET_STATUS:
            return {
                ...state,
                users: {
                    ...state.users,
                    [action.payload.uid]: {
                        ...state.users[action.payload.uid],
                        baseData: {
                            ...state.users[action.payload.uid].baseData,
                            statusText: action.payload.statusText
                        }
                    }
                },
            };

        default:
            return state;
    }
}
