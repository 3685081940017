import React, { FC, useMemo } from 'react'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { Icon } from 'uielements/src/Icon/Icon';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { Translate } from "localization";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import * as utils from 'utils/src/utils';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider, KeyboardDatePicker } from "@material-ui/pickers";
import Autocomplete from 'uielements/src/MaterialElements/Autocomplete'
import { Button, CheckboxInput, Loading, Input as PryanikyInput } from 'uielements/src';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noEditblField: {
            textDecoration: "none",
            margin: "25px 0 0 0",
            borderBottom: "1px solid #999",
            width: "100%"
        },
        noEditblFieldSpan: {
            color: "#999",
            fontSize: "13px"
        },
        noEditblFieldP: {
            margin: "0"
        },
        formControl: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
        noEditblFieldDivTags: {
            display: "flex",
        },
        noEditblFieldTags: {
            width: "fit-content",
            padding: "0 11px",
            margin: "0 5px 0 0",
            background: "#E3E3E3",
            borderRadius: "24px",
        },
    })
);


export const Interests: FC<any> = ({ field, onChangeFields, value, errorMessage }) => {
    const classes = useStyles();
    return (
        field && <FormControl fullWidth key={field.id} id={field.id} component="fieldset" className={classes.formControl}>
                <Autocomplete
                    allowCreate={!field.selectOnlyFromCollection}
                    fullWidth
                    type={'tags'}
                    col={'profile'}
                    additionalRequestProps={{colId: field.linkedCollection?.id}}
                    fieldId={field.id}
                    // label={<Translate i18nKey={`pryaniky.user.fields.${field.id}`} defaults={field.name} />}
                    value={value || undefined}
                    onChange={onChangeFields(field.id, 'tags')}
                    placeholder={`${Translate.t({ i18nKey: `pryaniky.user.fields.${field.id}`, defaults: field.name })}${field.isMandatory ? ' *' : ''}`}
                    endAdornment={errorMessage && <HelpTooltip help={errorMessage}><Icon icon="exclamation-circle" className={'Input-ErrorIcon'} /></HelpTooltip>}
                />
            </FormControl>
    )
}

export default Interests