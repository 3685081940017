import {
  ILoginActions,
  ILoginCheckUserInvitationActionPayload,
  ILoginServiceChangeActionPayload,
  ILoginSetCaptcha
} from "./actions.index";
import { ISubnetwork } from "utils/src/requests/models/api.system";
import { ILoginProvider, ILoginReducer } from "./interfaces";

export const LOGIN_AUTHENTICATE = 'LOGIN_AUTHENTICATE';
export const LOGIN_PASSWORD_RESET = 'LOGIN_PASSWORD_RESET';
export const LOGIN_PASSWORD_SET = 'LOGIN_PASSWORD_SET';
export const LOGIN_FIRST_LOGIN = 'LOGIN_FIRST_LOGIN';
export const LOGIN_AUTHENTICATE_ERROR = 'LOGIN_AUTHENTICATE_ERROR';
export const LOGIN_AUTHENTICATE_SUCCESS = 'LOGIN_AUTHENTICATE_SUCCESS';
export const LOGIN_AUTHENTICATE_ERROR_CLEAR = 'LOGIN_AUTHENTICATE_ERROR_CLEAR';
export const LOGIN_AUTHENTICATE_SUCCESS_CLEAR = 'LOGIN_AUTHENTICATE_SUCCESS_CLEAR';
export const LOGIN_CHANGE_FIELD = 'LOGIN_CHANGE_FIELD';
export const LOGIN_SET_REQUEST = 'LOGIN_SET_REQUEST';
export const LOGIN_LOGOUT = 'LOGIN_LOGOUT';
export const LOGIN_GET_COMPANY_SETTINGS = 'LOGIN_GET_COMPANY_SETTINGS';
export const LOGIN_SET_SUBNETWORKS = 'LOGIN_SET_SUBNETWORKS';
export const LOGIN_SET_PROVIDERS = 'LOGIN_SET_PROVIDERS';
export const LOGIN_SELECT_PROVIDER = 'LOGIN_SELECT_PROVIDER';
export const LOGIN_SEND_SMS_CODE = 'LOGIN_SEND_SMS_CODE';
export const LOGIN_CHECK_USER_INVITATION = 'LOGIN_CHECK_USER_INVITATION';
export const LOGIN_SET_SETTINGS = 'LOGIN_SET_SETTINGS';
export const LOGIN_CHANGE_SERVICE = 'LOGIN_CHANGE_SERVICE';
export const LOGIN_SET_CAPTCHA = 'LOGIN_SET_CAPTCHA';
export const LOGIN_UPDATE_CAPTCHA = 'LOGIN_UPDATE_CAPTCHA';
export const LOGIN_CHANGE_REDUCER_FIELD = 'LOGIN_CHANGE_REDUCER_FIELD';

export function login(): ILoginActions {
  return {
    type: LOGIN_AUTHENTICATE
  }
}

export function login_password_reset(): ILoginActions {
  return {
    type: LOGIN_PASSWORD_RESET
  }
}

export function login_password_set(payload: string): ILoginActions {
  return {
    type: LOGIN_PASSWORD_SET,
    payload
  }
}

export function login_first_login(payload: string): ILoginActions {
  return {
    type: LOGIN_FIRST_LOGIN,
    payload
  }
}

export function login_error(payload: {[s: string]: string}): ILoginActions {
  return {
    type: LOGIN_AUTHENTICATE_ERROR,
    payload
  }
}

export function login_success(payload: {[s: string]: string}): ILoginActions {
  return {
    type: LOGIN_AUTHENTICATE_SUCCESS,
    payload
  }
}

export function login_error_clear(): ILoginActions {
  return {
    type: LOGIN_AUTHENTICATE_ERROR_CLEAR
  }
}

export function login_success_clear(): ILoginActions {
  return {
    type: LOGIN_AUTHENTICATE_SUCCESS_CLEAR
  }
}

export function login_change_field(payload: {[s: string]: string | boolean}): ILoginActions {
  return {
    type: LOGIN_CHANGE_FIELD,
    payload
  }
}

export function login_set_request(payload?: boolean): ILoginActions {
  return {
    type: LOGIN_SET_REQUEST,
    payload
  }
}

export function login_logout(): ILoginActions {
  return {
    type: LOGIN_LOGOUT
  }
}

export function login_get_company_settings(): ILoginActions {
  return {
    type: LOGIN_GET_COMPANY_SETTINGS
  }
}

export function login_set_subnetworks(payload: ISubnetwork[]): ILoginActions {
  return {
    type: LOGIN_SET_SUBNETWORKS,
    payload
  }
}

export function login_set_providers(payload: ILoginProvider[]): ILoginActions {
  return {
    type: LOGIN_SET_PROVIDERS,
    payload
  }
}

export function login_select_provider(payload: string): ILoginActions {
  return {
    type: LOGIN_SELECT_PROVIDER,
    payload
  }
}

export function login_send_sms_sode(): ILoginActions {
  return {
    type: LOGIN_SEND_SMS_CODE
  }
}

/**
 * check user invitation action
 * @param { string } userId - user id
 * @param { string } inv - user invitation password
 */
export function login_check_user_invitation(payload: ILoginCheckUserInvitationActionPayload): ILoginActions {
  return {
    type: LOGIN_CHECK_USER_INVITATION,
    payload
  }
}

export function login_set_settigns(payload: {[s: string]: any}): ILoginActions {
  return {
    type: LOGIN_SET_SETTINGS,
    payload
  }
}

export function login_change_service(payload: ILoginServiceChangeActionPayload): ILoginActions {
  return {
    type: LOGIN_CHANGE_SERVICE,
    payload
  }
}

export function login_set_captcha(payload: ILoginSetCaptcha['payload']): ILoginActions {
  return {
    type: LOGIN_SET_CAPTCHA,
    payload
  }
};

export function login_update_captcha(): ILoginActions {
  return {
    type: LOGIN_UPDATE_CAPTCHA
  }
};

export const login_change_reducer_field = <Field extends keyof ILoginReducer, > (field: Field, value: ILoginReducer[Field]): ILoginActions => {
  return {
    type: LOGIN_CHANGE_REDUCER_FIELD,
    payload: {
      field,
      value
    }
  };
}