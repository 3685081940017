import { ISagaModule } from 'redux-dynamic-modules-saga';
import { ILoginReducer } from './interfaces';
import { loginReducer } from './reducer';
import { loginSaga } from './saga';

export type ILoginModule = { login: ILoginReducer } 

export function getLoginModule(): ISagaModule<ILoginModule> {
  return {
    id: "login",
    reducerMap: {
      login: loginReducer,
    },
    sagas: [
      loginSaga
    ],
  };
}