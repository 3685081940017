import { IModuledStoreType } from "../store";

/**
 * get store reducer state
 * @param { IModuledStoreType } state 
 */
export const getStore = (state: IModuledStoreType) => state.store;

/**
 * get store auth user id
 * @param { IModuledStoreType } state 
 */
export const getAuthUserId = (state: IModuledStoreType) => getStore(state).authUid;

/**
 * get site url from settings
 * @param { IModuledStoreType } state 
 */
export const getSiteUrl = (state: IModuledStoreType) => (getStore(state).appSettings || {}).siteURL;