import { createStore, IModuleStore } from "redux-dynamic-modules";
import createSagaMiddleware from 'redux-saga';
import { applyMiddleware, compose, ReducerFromReducersMapObject, StateFromReducersMapObject } from 'redux';
import { getSagaExtension, ISagaModule,  } from "redux-dynamic-modules-saga";
import { getThunkExtension } from "redux-dynamic-modules-thunk";
import { getBaseModule } from "./reduxBaseModule";
import { getLoginModule } from "./login/reduxModule";
import { getRegistrationModule } from "./registration/reduxModule";
import { getFeedbackModule } from "./feedback/reduxModule";
import { getI18nModule } from 'utils/src/CommonRedux/i18n/reduxModule';

// используется для расширения типа стора динамичсекими модулями
type WithModuleStore<T extends () => ISagaModule<any>> = StateFromReducersMapObject<Required<ReturnType<T>>['reducerMap']>;

export const store = createStore(
  {
    initialState: {
      /** initial state */
    },
    enhancers: [
      // window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (data: any) => data
      // sagaMiddleware
      // routerMiddleware(history), // for dispatching history actions
      // applyMiddleware(
      //   // _AIChallngeMiddleware,
      //   // eventMiddleware,
      //   // titleMiddleware,
      //   // , 
      //   sagaMiddleware,
      //   thunk
      //   // ... other middlewares ...
      // ),
      // compose(
      //   window.__REDUX_DEVTOOLS_EXTENSION__ ? window.__REDUX_DEVTOOLS_EXTENSION__() : (data: any) => data
      // )
        /** enhancers to include */ 
    ], 
    extensions: [
      getSagaExtension(),
      getThunkExtension(),
      (window as any).__REDUX_DEVTOOLS_EXTENSION__ ? (window as any).__REDUX_DEVTOOLS_EXTENSION__() : (data: any) => data
      /** extensions to include i.e. getSagaExtension(), getThunkExtension() */
    ],
  },
  getBaseModule(),
  getRegistrationModule(),
  getI18nModule(),
  getLoginModule(),
  getFeedbackModule()
);

(window as any).store = store;

export type IModuledStoreType = ReturnType<typeof store.getState>