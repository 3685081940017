import React, { FC, useMemo } from 'react'
import { HelpTooltip } from 'uielements/src/HelpTooltip/HelpTooltip';
import { Icon } from 'uielements/src/Icon/Icon';
import FormControl from '@material-ui/core/FormControl';
import { TextField } from '@material-ui/core';
import { Translate } from "localization";
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        noEditblField: {
            textDecoration: "none",
            margin: "25px 0 0 0",
            borderBottom: "1px solid #999",
            width: "100%"
        },
        noEditblFieldSpan: {
            color: "#999",
            fontSize: "13px"
        },
        noEditblFieldP: {
            margin: "0"
        },
        formControl: {
            flex: '1 1 100%',
            marginTop: '16px',
        },
    })
);


export const Textarea: FC<any> = ({ field, onChangeFields, value, errorMessage }) => {
    const classes = useStyles();

    return (
        field && <FormControl fullWidth key={field.id} component="fieldset" id={field.id} className={classes.formControl}>
                <TextField
                    multiline
                    variant="outlined"
                    rows={4}
                    fullWidth
                    placeholder={`${Translate.t({ i18nKey: `pryaniky.user.fields.${field.id}`, defaults: field.name })}${field.isMandatory ? ' *' : ''}`}
                    value={value}
                    onChange={e => onChangeFields(field.id, 'value')(e.currentTarget.value)}
                    InputProps={{
                        endAdornment: errorMessage && <HelpTooltip help={errorMessage}><Icon icon="exclamation-circle" className={'Input-ErrorIcon'} /></HelpTooltip>
                    }}
                />
            </FormControl>
    );

}

export default Textarea