export const loginProvidersOnWeb = {
  sms: 'sms',
  otpbysms: 'otpbysms',
  login: 'login',
  forms: 'forms',
  adfs: "adfs", 
  federation: "federation", 
  o365: "o365",
  openid: 'openid',
  windows: 'windows',
  ldap: 'ldap'
};

// провайдеру у который форма авторизации стандартная(логин/пароль)
export const providersWithLoginPassFrom = [ loginProvidersOnWeb.forms, loginProvidersOnWeb.login, loginProvidersOnWeb.ldap ];

export const baseProvidersRedirect = [ loginProvidersOnWeb.adfs, loginProvidersOnWeb.federation, loginProvidersOnWeb.o365, loginProvidersOnWeb.openid, loginProvidersOnWeb.windows ];

export const useLoginProvidersOnWeb = Object.keys(loginProvidersOnWeb).map(e => loginProvidersOnWeb[e as keyof typeof loginProvidersOnWeb]);